import { render, staticRenderFns } from "./Detail.vue?vue&type=template&id=d5eabe66&"
import script from "./Detail.vue?vue&type=script&lang=js&"
export * from "./Detail.vue?vue&type=script&lang=js&"
import style0 from "./Detail.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/Users/declan/code/fourpointzero/portal/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {VCard,VFlex,VLayout})
