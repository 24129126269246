const awsdetails = {
    Auth:{
        region: process.env.VUE_APP_AWS_REGION,
        userPoolId: process.env.VUE_APP_USERPOOLID,
        userPoolWebClientId : process.env.VUE_APP_USERPOOL_CLIENTID
    },

    Analytics: {
        disabled: true
    }
}

export default awsdetails;