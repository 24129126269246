
<template>
 
    <transition-group name="fade">
        <div key="results">
            <results-table v-if="!viewDetails" @itemselected="itemSelected"></results-table>
        </div>
        <div key="detail">
            <detail-view v-if="viewDetails && selectedItem" :item="selectedItem" @goback="goBack"></detail-view>   
        </div>
    </transition-group>

</template>

<script>

import ResultsTable from '../components/ResultsTable.vue'
import DetailView from './Detail.vue'

export default {


    name: 'Dashboard',

    components: { 
        ResultsTable,
        DetailView
     },

    data() {
        return {
            viewDetails: false,
            selectedItem: null
        }
    },


    mounted(){
        this.$store.commit('currentRoute', 'Predictions')
    },


    methods: {

        preloadItem(item){
            this.selectedItem = item;

            if(item && item.lot){
                this.$store.dispatch('getUnit', item.lot).then(()=>{
                        this.$store.commit('currentLot', item);

                })
            }
        },

        itemSelected(item){
            this.selectedItem = item;
            console.log('item:', item)
            if(item && item.lot){
                this.$store.dispatch('getUnit', item.lot).then(()=>{
                        this.$store.commit('currentLot', item);
                        
                        this.$store.commit('initialSameness', item.sameness_score)
                        this.viewDetails = true;
                        
                })
            
            
            }
        },

        goBack(){
            this.viewDetails = false;
        }
        
    }
}


</script>


<style>

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .3s;
}

.fade-enter-active {
  transition-delay: .3s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}
</style>

