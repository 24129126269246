
<template>

<v-container class="grey lighten-4" fluid>
    
    <h2 class="mb-4 mx-4 unit-title">File List</h2>

    <v-container v-if="loading">
        <v-layout align-center>
            <v-subheader>Loading data please wait...</v-subheader>

            <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
        </v-layout>
    </v-container>
    
    <v-card v-if="!loading" class="mb-5 px-3 grey lighten-4 elevation-0" color="">
        
        <file-list :filelist="fileList"></file-list>
        
    </v-card>
   
    
        
    
</v-container>

</template>

<script>

import FileList from '../components/FileList.vue'

export default {


    name: 'UploadedFiles',

    data(){
        return {
            loading: false
        }
    },

    components: { 
        FileList
     },

    
    mounted(){
        this.loading = true;
        this.$store.dispatch('getFiles').then(() => {
            this.loading = false;
        })
        this.$store.commit('currentRoute', 'Previous files')
    },


    computed: {
        fileList(){
            return this.$store.getters['fileList']
        }
    },


    methods: {

        // itemSelected(item){
        //     this.selectedItem = item;
        //     this.chartView = true;

        // },

        // closeChart(){
        //     this.chartView = false;
        // }
        
    }
}


</script>
