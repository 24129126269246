import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'

import $api from './api'

import flatten from 'flat'
import utils from './utils'
import { SSL_OP_NETSCAPE_DEMO_CIPHER_CHANGE_BUG } from 'constants';
//import { Parser } from 'json2csv'

var cacheDuration = 60;

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentRoute: 'Submit data',
    baseUrl: '',
    currentFile: null,
    predictionData: [],
    fileList: [],

    // data predictions
    currentLot: null,
    exisitingPredictions: [],

    // data measurements
    currentUnitData: null,
    savedUnits: [],
    unitSearch: '',
    excludeComplete: false,
    lastExistingPredictionsFetch: {},
    existingPredictions: {},
    trainingLimits: [],
    modelData: {},
    currentModel: null,
    currentModelTitle: null,
    modelList: [],
    currentHistory: [],
    initialSameness: 0


  },
  mutations: {
    currentRoute(state, data) {
      state.currentRoute = data;
    },

    predictionData(state, data) {
      if(data){

        state.predictionData = data;
      }
      else{
        state.predictionData = [];
      }
    },

    baseUrl(state, data) {
      state.baseUrl = data;
    },

    fileList(state, data) {
      state.fileList = data;
    },

    currentFile(state, data){
      state.currentFile = data;
    },

    modelList(state, data){
      state.modelList = data;
    },

    modelData(state, data){
      state.modelData[data.id] = data.data
    },

    currentLot(state, data){
      console.log('current lot set:', data)
      state.currentLot = data;
    },

    currentUnitData(state, data){
      console.log('current unit data set:', data)
      state.currentUnitData = data;
    },
    
    currentModel(state, data){
      state.currentModel = data;
    },

    currentModelTitle(state, data){
      state.currentModelTitle = data;
    },

    existingPredictions(state, data){
      state.exisitingPredictions[data.modelid] = data.data;
    },

    lastExistingPredictionsFetch(state, data){
      state.lastExistingPredictionsFetch[data.modelid] = { datetime: data.datetime }
    },

    trainingLimits(state, data){
      state.trainingLimits = data;
    },

    saveUnit(state, data){
      var unit = state.savedUnits.find(u => { return u.lot == data.lot && u.modelid == data.modelid });
      if(!unit){
        state.savedUnits.push(data)
      }
    },

    unitSearch(state, data){
      state.unitSearch = data
    },

    excludeComplete(state, data){
      state.excludeComplete = data;
    },

    currentHistory(state, data){
      state.currentHistory = data
    },

    initialSameness(state, data){
      state.initialSameness = data;
    }

  },
  actions: {

    

    getNewPrediction(context, data){
      var payload = flatten(data.unit);


      delete payload._id;
      //delete payload.field1;
      delete payload.filekey;
      delete payload.filename;
      delete payload.dateupdated;
      delete payload.modelid;

      var measured = payload.percentageMeasured;
      
      delete payload.percentageMeasured;
      
      payload[data.node.id] = data.value.trim() == '' ? 'NA': data.value.toString()

      Object.keys(payload).forEach(k => {
        if(k != 'modelid' && k != '_id' && k != 'percentageMeasured' && payload[k] != 'NA'){
          payload[k] = parseFloat(payload[k])
        }
      })

      payload = [payload]
      console.log('payload: ', payload)
      return $api.post('/api/predict/update/' + context.state.currentModel + '/' + data.unit.lot, payload).then(res => {
        var pred = res.data && res.data[0] || null;
        
        if(pred){
          pred = JSON.parse(res.data[0])
          if(pred.length && pred.length > 0){
            pred = pred[0]
            pred.percentageMeasured = measured;
          }
          else{
            pred = null;
          }
        }
        console.log('prediction result', pred)
        context.commit('currentLot', pred)
        context.commit('currentUnitData', data.unit)
      })
    },

    findSavedUnit(context, data){
      // console.log('finding saved unit')
      var unit = context.state.savedUnits.find(u => { return u.lot === data.lot && u.modelid === data.modelid });
      return unit;
    },

    getUnit(context, lot){
      // console.log('searching for: ', data)
      var data = { lot: lot, modelid: context.state.currentModel };

      return context.dispatch('findSavedUnit', data).then(ex =>{

        if(ex){
         
          context.commit('currentUnitData', ex);
          return ex;
        }
        else{
          // console.log('didnt find it')
          return $api.get("/api/predict/findunit/" + data.modelid + '/' + data.lot).then(res => {
            var unit = res.data;
            // console.log('got unit:', unit);
    
            context.commit('currentUnitData', unit);
            context.commit('saveUnit', unit)
    
            return unit;
          })
        }
        
      })
      
    },


    getModelData(context, data){

      if(context.state.modelData[data.id]){
        return context.state.modelData[data.id]
      }
      return $api.get(context.state.baseUrl + '/api/modeldata/' + data.id).then(res => {

        if(res.data){
          context.commit("modelData", { id: data.id, data: res.data })

          var trainingLimits = res.data.nodes.map(n => {
            return { Name: n.id, Lower: n.lower, Upper: n.upper}
          })

          context.commit("trainingLimits", trainingLimits)
          return res.data;
        }
        else{
          
          return null;
        }
        
      })
    },

    

    getNodeData(context, nodeData){
      var data = null;
      if(nodeData.useExisting){
        data = context.state.exisitingPredictions[nodeData.modelid]
      }
      else{
        data = context.state.predictionData;
      }
      if(data){
        var name = 'Prob. ' + nodeData.name;
        data = data.find(x => {
          // console.log(x)
          return x.lot == nodeData.lot
        })
      }

      return data;
      // return context.dispatch('getUnit', data.lot).then(res => {
      //   return data;
      // })

    },

    uploadClientData(context, form) {

      console.log(form.model)
      context.commit('currentFile', form.file.name);
      return $api.post(context.state.baseUrl + '/api/predict/upload/' + form.model, form.formData).then(results => {
        // var sameness = JSON.parse(results.data[0])
        // sameness = sameness.sort(function (d, e) {
          
        //   return d.sameness_score - e.sameness_score;
        // });

        // var uploadDate = new Date();
        // sameness.forEach(s => {
        //   s.filename = form.file.name;
        //   s.dateuploaded = form.dateUploaded;
        //   s.modelid = form.model;
        // })
        // context.commit('predictionData', sameness);
        // // //console.log(sameness)
        // return results.data;
      })
      // .catch(err => {
      //   // //console.log(err);
      // })

    },

    getModelList(context){

      return $api.get(context.state.baseUrl + 'api/modeldata/listmodels').then(results => {
        
        context.commit('modelList', results.data);
        return results.data;
      })
      .catch(err => {

      })
    },

    getFiles(context) {
      return $api.get(context.state.baseUrl + '/api/datafiles').then(results => {
        context.commit('fileList', results.data);
        // //console.log(results.data)
        return results.data;
      }).catch(err => {
        // //console.log(err)
      })
    },

    getExistingUnits(context){

      // console.log('getting units')
      var lastFetch = context.state.lastExistingPredictionsFetch[context.state.currentModel]
      console.log('last fetch:', lastFetch)
      if(lastFetch && context.state.exisitingPredictions[context.state.currentModel]){
        // console.log('last fetch')
        
        if(lastFetch.datetime.isAfter(moment().subtract(cacheDuration,'seconds')) && context.state.exisitingPredictions[context.state.currentModel].length > 0){
          return context.state.exisitingPredictions[context.state.currentModel]
        }
        
      }
      return $api.get(context.state.baseUrl + '/api/predict/list/' + context.state.currentModel).then(results => {
        context.commit('lastExistingPredictionsFetch', { modelid: context.state.currentModel, datetime: moment() })
        context.commit('existingPredictions', { modelid: context.state.currentModel, data: results.data.sort(function(a, b){
          if(a.sameness_score < b.sameness_score){
            return -1;
          }
          if(a.sameness_score > b.sameness_score){
            return 1;
          }
          return 0;
        })});
        // //console.log(results.data)
        return results.data;
      }).catch(err => {
        // //console.log(err)
      })
    },

    flattenObject(context, data){
      return flatten(data);
    },

    convertToCSV(context, data){
      // const parser = new Parser();
      // const csv = parser.parse(data);
      // return csv;
    },

    getUpStreamNodes(context, data){

      console.log('data 2:' , data)
      function getUpNodes(nodeLinks, node, nodes, level){
        
        nodeLinks.forEach(nd => {
          //console.log('nd: ', nd)
          if(nd.target.id == node.id){
            nodes.push({ id: nd.source.id, strength: nd.strength, level: level });
            nd.id = nd.source.id;
            getUpNodes(nodeLinks.filter( n => n != nd), nd.source, nodes, (level + 1));
          }

        })

        return nodes;
      }
      
      var modelData = (context.getters['modelData'])[context.getters['currentModel']];
      var resultNodes = [];

      if(modelData && modelData.links){
        var lev = 1;
        getUpNodes(modelData.links, data, resultNodes, 1);
      }
      console.log('result nodes:', resultNodes)
      
      
      return resultNodes.sort((a,b) => {
        if(a.level > b.level){
          return 1;
        }
        if(b.level > a.level){
          return -1;
        }
        return 0;
      });
      
    },

    getDownStreamNodes(context, data){

      function getNodes(nodeLinks, node, nodes){
        
        nodeLinks.forEach(nd => {
          //console.log('nd: ', nd)
          if(nd.source.id == node){
            nodes.push(nd.target.id);
        
            getNodes(nodeLinks.filter( n => n != nd), nd.target.id, nodes);
          }

        })

        return nodes;
      }
      
      var modelData = (context.getters['modelData'])[context.getters['currentModel']];
      var resultNodes = [];

      if(modelData && modelData.links){
        getNodes(modelData.links, data, resultNodes);
      }

      return resultNodes;
      
    },
    
    clearCurrentHistory(context){
      context.commit('currentHistory', [])
    },
    
    updateCurrentHistory(context, data){

      context.state.currentHistory.push(data)
    }

  }, // end actions

  getters: {

    trainingLimits: state => {
      return state.trainingLimits
    },

    currentRoute: state => {
      return state.currentRoute
    },

    predictionData: state => {
      return state.predictionData
    },

    fileList: state => {
      return state.fileList
    },

    currentLot: state => {
      return state.currentLot
    },

    currentUnitData: state => {
      return state.currentUnitData
    },

    existingPredictions: state => {
      return state.exisitingPredictions
    },

    unitSearch: state => {
      return state.unitSearch
    },

    excludeComplete: state => {
      return state.excludeComplete
    },

    modelData: state => {
      return state.modelData
    },

    modelList: state => {
      return state.modelList
    },

    currentModel: state => {
      return state.currentModel
    },

    currentModelTitle: state => {
      return state.currentModelTitle;
    },

    currentHistory: state => {
      return state.currentHistory;
    },

    initialSameness: state => {
      return state.initialSameness;
    }

  }
})
