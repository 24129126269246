<template>

    <div>
        <v-layout row>
            <v-flex xs12>

                <div class="ml-5 mt-4">

                    <h2 class="unit-title">Your Bespoke Analytics Models</h2>
                </div>

                <v-container v-if="loading" >
                    <v-layout align-center v-if="loading">
                        <v-subheader>Loading data please wait...</v-subheader>

                        <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
                    </v-layout>
                </v-container>
            </v-flex>
        </v-layout>

        <v-layout row wrap class="ml-4 mr-2" v-if="!loading">
            <v-flex v-if="!models || models.length === 0">
                <v-container>
                    <v-subheader>You currently have no active analytics models available.</v-subheader>
                </v-container>
            </v-flex>
            <v-flex xs12 sm12 md6 lg4 v-for="(model, idx) in models" :key="idx" class="">
                <v-container class="">
                    <model-summary :model="model" @modelselected="modelSelected" v-if="!loading"></model-summary>
                </v-container>
            </v-flex>
        </v-layout>


    </div>
    

  
</template>

<script>

import ModelSummary from '../components/ModelSummary'

export default {

    name: 'ModelDashboard',

    components: {
        ModelSummary
    },

    mounted(){
        this.loading = true;
        this.$store.commit('currentRoute', 'ModelDashboard')
        this.$store.dispatch('getModelList').then(res => {
            this.loading = false;
        })
    },

    computed: {
        models(){
            var ms = this.$store.getters['modelList'];

            //var mods = [ ms[0], ms[0], ms[0], ms[0], ms[0]]
            return ms;
        }
    },

    data(){

        return {
            loading: true
        }
    },


    methods: {

        modelSelected(model){

            this.$store.commit('currentModel', model.modelid)
            this.$store.commit('currentModelTitle', model.title)

            this.$store.dispatch('getModelData', { id: model.modelid }).then(res => {
                
                this.$router.push({ name: 'units' })
            })
        }
    }
}


</script>

<style>

</style>