<template>

    <div>

            
        <v-layout row>
            <v-flex xs10>

            
                <div style="max-width: 200px;">

                    <svg :id="div_id" ></svg>
                </div>
            </v-flex>
        
        </v-layout>
        

        
    </div>
    
    

</template>

<script>

import * as d3 from "d3";

export default {

    name: 'SamenessGauge',

    props: ['item', 'small', 'completeness', 'colorlist'],

    data() {
        return {
            div_id: this.item && this.item.lot && 'samenessguage_' + this.item.lot + Math.floor(Date.now() / 1000) + (this.completeness ? '_completeness' : '_sameness') || 'samenessgauge_nolot',
            selectedItem: this.item,
            dataset: { a: 0, b: 0 },
            samenessScore: 100,
            width: 400,
            height: 400,
            margin: 55,
            radius: 0,
            svg: null,
            tiny: this.small || false,
            measured: this.completeness || false,
            colorList: this.colorlist || [ "#76D6FF", "#E5E5E5"]

        }
    },

    mounted(){
        
        this.initd3();
            
    },

    watch: {
        'item':function(val) {
            this.selectedItem = val;
            this.svg.selectAll('g').remove();
            this.svg.selectAll('text').remove();
            this.initd3();

        }
    },

    methods: {

        round(value, precision) {
            var multiplier = Math.pow(10, precision || 0);
            return Math.round(value * multiplier) / multiplier;
        },

        initd3(){
            
            //console.log('rendering: ', this.div_id)
            if(this.item){
                var score = this.selectedItem.sameness_score;

                if(this.measured){
                    score = this.selectedItem.percentageMeasured;
                }

                //console.log(score)
                if(this.tiny){

                    this.samenessScore = Math.floor(score)
                }
                else{
                    this.samenessScore = this.round(score, 2).toFixed(2)
                }

                //console.log(this.samenessScore)
                //console.log('sameness: ', this.samenessScore)
            }
            this.radius = Math.min(this.width, this.height) / 2 - this.margin;

            this.svg = d3.select("#"+ this.div_id)
            //.append("svg")
                .attr("viewBox", "50 50 350 350")
            .append("g")
                .attr("transform", "translate(" + this.width / 2 + "," + this.height / 2 + ")")

            this.dataset = {a: this.samenessScore, b: 100 - this.samenessScore }

            var color = d3.scaleOrdinal()
                .domain(this.dataset)
                .range(this.colorList)

            var donut = d3.pie()
                .value(function(d) {return d.value; })

            var donutdata = donut(d3.entries(this.dataset))

            this.svg.selectAll('nodes')
                .data(donutdata)
                .enter()
                .append('path')
                .attr('d', d3.arc()
                    .innerRadius(100)   
                    .outerRadius(this.radius)
                )
                .attr('fill', function(d){ return(color(d.data.key)) })
            
            var self = this;
            var textTitle = this.svg.append("text").attr("text-anchor", "middle")
                .style('font-weight', 'bold')
                .style('font-family', 'museo-sans')

                if(self.tiny){
                    textTitle.style('font-size', '5.5em')
                        .attr('x', 3)
                        .attr('y', 20)
                }
                else{
                    textTitle.style('font-size', '2.8em')
                        .attr('x', 2)
                        .attr('y', 20)
                }
                
                textTitle.text(function(d){
                    //console.log('text score', self.samenessScore)
                    if(self.tiny || self.samenessScore == 100){

                        return Math.floor(self.samenessScore) + "%"
                    }
                    else{
                        
                        return self.samenessScore + "%"
                    }
                })

                this.svg.exit().remove()
        },

        update(){

        }
    }
}


</script>


<style>


</style>
