<template>

    <div class="ctq-select">
        <div class="dropdown">
            <v-select style="font-size: 0.5em" class="mr-2 mt-2"  flat :items="ctqList"  label="CTQ" placeholder="Select node" item-text="textlabel" item-value="id" @change="nodeSelected"></v-select>
        </div>
        <!-- <div class="dropdown">
            <select name="ctqList" label="CTQ" placeholder="none selected" item-text="id" @change="nodeSelected">
                <option v-for="(c, idx) in ctqList" :key="idx" :value="c">{{ c.id }}</option>
            </select>
        </div> -->
    </div>

</template>


<script>
export default {
    

    data(){

        return {
            defaultNode: { textlabel: 'Select node', id: 'Select node', isCTQ: false},
            selectedCTQ: null,

        }
    },
    

    computed: {

        ctqList(){
            var modelData = this.$store.getters["modelData"]
            var currentModel = this.$store.getters["currentModel"];
            var nodes = [];
            if(modelData[currentModel] && modelData[currentModel].nodes){
                //nodes = modelData[currentModel].nodes.filter(n => n.isCTQ)
                nodes = modelData[currentModel].nodes.sort(n => n.isCTQ).map(x => {
                    x.textlabel = x.isCTQ ? 'CTQ: ' + x.id : 'non CTQ: ' + x.id;
                    return x;
                })
            }
            
            nodes.unshift(this.defaultNode)
            return nodes;
        }
    },

    created(){
        this.selectedCTQ = this.defaultNode
    },


    methods: {

        nodeSelected(node){
            this.$emit("nodeselected", node)
        }

    }

}
</script>

<style>

.ctq-select .v-input__append-inner {display: none !important;}

.ctq-select {width: 300px;}
.ctq-select label {display: none;}
.ctq-select .dropdown {margin: 0 0 1.5em 0; font-size: 1.6em; font-weight: 300; position: relative; background: none; overflow: hidden; border: none; border-radius: 0; -webkit-transition: all .3s linear; -moz-transition: all .3s linear; transition: all .3s linear;}
.ctq-select .dropdown:after {content: 'expand_more'; font-family: "Material Icons"; font-style: normal; font-variant-caps: normal; font-variant-ligatures: normal; font-size: 0.8em; font-weight: 900; height: 1em; line-height: 1em; text-rendering: auto; width: 1em; -webkit-font-smoothing: antialiased; position: absolute; right: 10px; top: 12px; z-index: 1; pointer-events: none; color: #43425D;}
.ctq-select .dropdown .v-select__selections {cursor: pointer;}
</style>
