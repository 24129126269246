import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
import './stylus/main.styl'
import Amplify, * as AmplifyModules from "aws-amplify"
import { AmplifyPlugin } from "aws-amplify-vue"
import awsconfig from './aws-exports'

const url = window.aalbaseurl;

Amplify.configure(awsconfig)

Vue.config.productionTip = false

Vue.use(AmplifyPlugin, AmplifyModules);


new Vue({
  router,
  store,
  data: {
    baseurl: url || ''
  },
  render: h => h(App)
}).$mount('#app')
