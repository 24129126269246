<template>
  
  <v-container class="grey lighten-4" v-if="ready" fluid>
      <h2 class="mb-4 mx-4 unit-title">Upload Data</h2>

      <v-card class="mb-5 px-3 grey lighten-4 elevation-0">

      <v-card>
       
    <v-stepper v-model="mstep" class="elevation-0">
        <v-stepper-header class="elevation-0">
            <v-stepper-step :complete="mstep > 1" step="1" :color="mstep == 1 ? 'error' : 'primary'">Select a model</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="mstep > 2" step="2" :color="mstep == 2 ? 'error' : 'primary'">Select a data file to submit</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="finished" step="3" :color="mstep == 3 ? 'error' : 'primary'">View results</v-stepper-step>

        </v-stepper-header>

    <v-stepper-items>
        <v-stepper-content step="1">
            <v-card class="mb-5 elevation-0" height="200px">
                <v-container id="ddlmodel">

                    <v-select class="aal-select" solo flat  v-if="mstep == 1" :items="models" item-text="name" item-value="modelid" placeholder="Please select..." v-model="selectedModel"></v-select>
                </v-container>
            </v-card>
            <v-btn color="secondary text-none" @click="modelSelected" :disabled="!selectedModel">Continue</v-btn>
            <!-- <v-btn flat @click="cancelProcess">Cancel</v-btn> -->
        </v-stepper-content>

        <v-stepper-content step="2">
            <div class="mb-5 elevation-0" height="200px">
                <v-container v-if="uploading">
                    <v-layout align-center>
                        <v-subheader>Checking file please wait...</v-subheader>

                        <v-progress-circular indeterminate color="primary" v-if="uploading"></v-progress-circular>
                    </v-layout>
                </v-container>
                <div fluid>
                    <v-card style="min-height: 200px;" flat>
                        <file-upload @fileuploaded="gotFile" v-if="!uploading" label="Choose file" class="mb-3"></file-upload>
                    
                        <!-- <file-table :filedata="fileData" v-if="fileData"></file-table> -->
                        <v-card-title>

                            <v-list two-line>
                                <v-list-tile>
                                    <v-list-tile-content>
                                        <v-list-tile-title>File name:
                                        </v-list-tile-title>
                                        <v-list-tile-sub-title>{{fileName}}
                                        </v-list-tile-sub-title>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-list-tile>
                                    <v-list-tile-content>
                                        <v-list-tile-title>Record count:
                                        </v-list-tile-title>
                                        <v-list-tile-sub-title>{{recordCount}}
                                        </v-list-tile-sub-title>
                                    </v-list-tile-content>
                                </v-list-tile>
                            </v-list>
                            
                        </v-card-title>
                    </v-card>
                    
                </div>
            </div>
            <v-btn color="secondary text-none" @click="submitForProcessing" :disabled="!fileData || uploading">Submit for processing</v-btn>
            <v-btn class="text-none" flat @click="cancelProcess">Cancel</v-btn>
        </v-stepper-content>


        <v-stepper-content step="3">
            <div class="mb-5" style="min-height: 200px">
                <v-container v-if="uploading">
                    <v-layout align-center>
                        <v-subheader>Please wait. Uploading file and generating sameness scores...</v-subheader>

                        <v-progress-circular indeterminate color="primary" v-if="uploading"></v-progress-circular>
                    </v-layout>
                </v-container>
                <div v-if="!uploading" class="">
                
                        <div v-if="uploadError">
                            <p>Sorry, there was a problem uploading the file.</p>
                            <p>{{ uploadError }}</p>
                
                        </div>

                        <div v-if="!uploadError">
                            <p>Your file has been uploaded and queued for processing. The new Sameness Scores will appear in the sameness scores list once they are ready.</p>
                            <p>You can view the status of your file on the File Uploads screen</p>
                    
                        </div>    
                        
                </div>
            </div>
            
            
            <v-btn color="secondary text-none" @click="cancelProcess">Start again</v-btn>
            <!-- <v-btn flat @click="cancelProcess">Cancel</v-btn> -->
        </v-stepper-content>


        
    </v-stepper-items>
  </v-stepper>
  </v-card>
  </v-card>
  </v-container>
</template>

<script>
  import Splash from '../components/Splash'
  import FileUpload from '../components/FileUpload.vue'
  import FileTable from '../components/FileTable.vue'
  import Papa from 'papaparse'
  import { setTimeout, setImmediate } from 'timers';
  
  export default {

    name: 'ModelStepper',

    components: {
      Splash,
      FileUpload,
      FileTable
    },

    data() {

        return {
          mstep: 0,
          finished: false,
          selectedModel: null,
          fileProcessed: false,
          uploadComplete: false,
          uploading: false,
          fileData: null,
          fileInfo: null,
          ready: false,
          recordCount: 0,
          fileName: '',
          uploadError: false
        }
    },

    

    created(){
        this.$store.commit('currentRoute', 'Submit data')

        this.$store.dispatch('getModelList');

        if(this.$store.getters["predictionData"] && this.$store.getters['predictionData'].length > 0){

            setImmediate(() => {
                this.mstep = 3;
                this.ready = true
            })
        }
        else{
            setImmediate(() => {
                this.mstep = 1;
                this.ready = true
            })
        }
    },


    computed: {

        selectedScore(){
            return this.$store.getters['currentLot']
        },

        models(){
            return this.$store.getters['modelList']
        }
    },

    methods: {

        

        cancelProcess() {
            this.fileData = null;
            this.finished = false;
            this.mstep = 1;
        },

        modelSelected(){

            setTimeout(() => {
                this.mstep = 2;
            }, 150)
            
        },

        submitForProcessing(){
            this.uploadError = false;
            this.uploading = true;
            this.mstep = 3;
            this.uploadFile(this.fileInfo).then(res => {
                setTimeout(() => {

                    this.uploading = false;
                }, 1500)
            })
            .catch(err => {
                this.uploadError = err;
                setTimeout(() => {

                    this.uploading = false;

                }, 1500)
            })
        },

        uploadFile(file){

            if(file){

                const formData = new FormData();
                formData.append('file', file);
                var uploadedDate = new Date();
                return this.$store.dispatch('uploadClientData', { formData: formData, file: file, dateUploaded: uploadedDate, model: this.selectedModel  });
    
            }
        },

        showFile(results){
          //console.log(results.data)
          this.fileData = results;//.data;
          this.fileProcessed = true;
          //this.finished = true;
        },

        submitToModel(){

            if(this.fileInfo){
              this.uploading = true;
              this.uploadFile(this.fileInfo).then(() => {
                this.uploading = false;
                this.$router.push({ name: 'results' })
              })
            }
            
        },

        gotFile(file){

            var self = this;
            this.recordCount = 0;
            this.fileName = file.name;
            this.fileData = [];
            
            const step = function(results, parser){

                //console.log(results.data[0])
                results.data.forEach(element => {
                    //self.fileData.push(element);
                    self.recordCount++

                });
            

            }
            
            this.fileInfo = file;
            this.uploading = true;
            Papa.parse(file, {
              header: true,
              skipEmptyLines: true,
              step: step,
              complete: (res) => {
                
                setTimeout(() => {
                    self.uploading = false;
                    //self.showFile(self.fileData);
                    self.fileProcessed = true
                }, 500)
                
              }
            })

            
        }
    }
  }
</script>

<style>

 
</style>
