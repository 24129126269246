
<template>

<v-data-table :headers="headers" :items="fileData" :rows-per-page-items='[10,25,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]' class="elevation-1" light>

    <template v-slot:items="props">
      <td class="">{{ props.item.lot }}</td>
      
    </template>
  
</v-data-table>


</template>

<script>
export default {
    
    name: 'FileTable',

    props: ['filedata'],

    data(){
        
        return {

            headers: [
                { text: 'Unit', value: 'lot' }
                
            ],

            fileData: this.filedata

        }

    }

    
}
</script>

