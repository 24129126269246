<template>


     <v-layout row px-3 pr-4 py-2>
        <v-flex>

          <v-layout row wrap>
            <v-flex>
              <p class="unit-subtitle pl-2 pt-2">Network Graph</p>
            </v-flex>

            <v-flex class="toggle-form">
              <div class="checkbox">
                <input id="toggleLabels" v-model="toggleLabels" name="marketing" value="Toggle CTQ labels" type="checkbox">
                <label for="toggleLabels">Toggle CTQ labels</label>
                <span class="checkmark"></span>
              </div>
            </v-flex>
            <v-flex class="toggle-form">
              <div class="checkbox">
                <input id="toggleExplanatories" v-model="toggleExplanatories" name="marketing" value="Toggle non CTQ labels" type="checkbox">
                <label for="toggleExplanatories">Toggle non CTQ labels</label>
                <span class="checkmark"></span>
              </div>
            </v-flex>
            <v-flex>
              <ctq-list @nodeselected="ctqSelected"></ctq-list>
            </v-flex>
          </v-layout>

        <!-- <v-img src="network.png"></v-img> -->
        
          <d3chart v-if="showChart" @nodeselected="nodeSelected" :item="chartData" :ctq="selectedCTQ" :toggletext="toggleLabels" :togglenonctqs="toggleExplanatories" ref="networkgraph"></d3chart>

            <v-dialog v-model="dialog" width="500" height="300" @blur="dialog = false">
     

              <div v-if="fetching">
                <v-card>

                  <v-container v-if="fetching">
                        <v-layout align-center>
                            <v-subheader>Fetching node...</v-subheader>

                            <v-progress-circular indeterminate color="primary" v-if="fetching"></v-progress-circular>
                        </v-layout>
                    </v-container>

                </v-card>
              </div>
              <div v-if="!fetching">
                <v-card v-if="selectedNode">
                <v-card-title class="grey lighten-4" >
                  <h4 class="unit-title ml-2">Node details <span>- {{selectedNode.id}}</span></h4>
                </v-card-title>

                <v-card-text v-if="!showUpstreams">
                  <div class="aal-status" :class="getChipClass(selectedNode) + ''"><div class="aal-inner"><span>{{getChipText(selectedNode)}}</span></div></div>
                  <div class="aal-step-value">
                    <h5>Value:</h5>
                    <v-text-field v-model="measuredValue" id="aalnodeinput" :error="invalid" @change="valueUpdated" @keyup="valueSubmitted"></v-text-field>
                    <p v-if="invalid" class="invalid">Value must be numeric</p>
                  </div>
                </v-card-text>

                <v-card-text v-if="showUpstreams">

                  <div style="max-height: 350px;overflow: auto;">

                    <div class="aal-influencer mb-3">  

                      <h5>Direct Influencers:</h5>
                      <div class="aal-list">
                        <ul>
                          <li v-if="!influencers || influencers.filter(i => i.level === 1).length === 0">No direct influencers</li>
                          <li v-for="(item, idx) in influencers.filter(i => i.level === 1)" :key="idx">
                              <b>{{item.id}}</b>
                          </li>
                        </ul>
                      </div>

                    </div>

                    <div class="aal-influencer mb-3">
                      
                      <h5>Indirect Influencers:</h5>
                      <div class="aal-list">
                        <ul>
                          <li v-if="!influencers || influencers.filter(i => i.level > 1).length === 0">No indirect influencers</li>
                          <li v-for="(item, idx) in influencers.filter(i => i.level > 1)" :key="idx">
                              <b>{{item.id}}</b> 
                          </li>
                        </ul>
                      </div>
                    
                    </div>

                  </div>

                </v-card-text>

                <div v-if="selectedLimits && !selectedNode.isCTQ && !showUpstreams" class="aal-acceptable mx-3 mb-3">
                  <h5>Training limits:</h5>
                  <div class="aal-lower-upper">
                    <span><i class="material-icons">arrow_downward</i> Lower: {{selectedLimits.Lower}}</span>
                    <span><i class="material-icons">arrow_upward</i> Upper: {{selectedLimits.Upper}}</span>
                  </div>
                </div> 

                <div v-if="selectedLimits && selectedNode.isCTQ && !showUpstreams" class="aal-acceptable mx-3 mb-3">
                  <h5>Acceptable range:</h5>
                  <div class="aal-lower-upper">
                    <span><i class="material-icons">arrow_downward</i> Lower: {{selectedLimits.Lower}}</span>
                    <span><i class="material-icons">arrow_upward</i> Upper: {{selectedLimits.Upper}}</span>
                  </div>
                </div> 

                <v-card-actions>
                  <v-spacer></v-spacer>
                  
                  <v-btn v-if="showUpstreams" color="grey lighten-2 text-none" @click="showUpstreams = false">
                    View Node Value
                  </v-btn>
                  <v-btn v-if="!showUpstreams" color="grey lighten-2 text-none" @click="viewInfluencers(selectedNode)">
                    View Influencers
                  </v-btn>
                  <v-btn color="secondary text-none" @click="closeDialog">
                    Cancel
                  </v-btn>
                  <v-btn v-if="!showUpstreams" color="primary text-none" @click="getNewPredictions" >
                    Apply
                  </v-btn>
                </v-card-actions>
              </v-card>
              </div>
              
              </v-dialog>
      
      
    </v-flex>
  </v-layout>
    
</template>


<script>

import d3chart from './ResultsD3.vue';
import CtqList from './CTQList.vue'
import Vue from 'vue'

export default {
    
    name: 'ResultsChart',

    props: ['item'],

    components: {
      d3chart,
      CtqList
    },

    data(){

        return {
            card_text: this.item && this.item.Name || 'Results',
            chartData: this.item || null,
            dialog: false,
            showChart: true,
            nodeValue: 0.89,
            selectedNode: null,
            fetching: false,
            measuredValue: null,
            currentMeasurement: null,
            invalid: false,
            limits: null,
            selectedCTQ: null,
            toggleLabels: false,
            toggleExplanatories: false,
            valueChanged: false,
            showUpstreams: false,
            influencers: []
            
        }
    },

    computed:{

      applyDisabled(){
        return this.valueChanged;
      },

      currentUnit(){
        return this.$store.getters['currentUnitData']
      },

      currentLot(){
        return this.$store.getters['currentLot']
      },

      trainingLimits(){
        return this.$store.getters['trainingLimits']
      },

      selectedLimits(){
        return this.limits
      },

      currentModel(){
        return this.$store.getters['currentModel']
      },

      currentHistory(){
        return this.$store.getters['currentHistory']
      }

    },

    

    methods: {

      closeDialog(){
        
        this.dialog = false;
        this.showUpstreams = false;
      },

      nodeHover(n){
        this.$refs.networkgraph.highlightNode(n)
      },

      nodeLeave(n){
        this.$refs.networkgraph.highlightNode(null)
      },

      viewInfluencers(node){
        this.showUpstreams = true;
        this.$store.dispatch('getUpStreamNodes', node).then(res => {
          console.log('influencers', res)
          this.influencers = res;
        })
      },

      valueUpdated(){

        this.valueChanged = true;
      },

        ctqSelected(node){
          this.selectedCTQ = node;
          this.valueChanged = false;
        },

        closePopUp(){
          this.showPopUp = false;
          this.valueChanged = false;
        },

        getChipClass(n){

          console.log('got node: ', n)
          console.log('is ctq:', n.isCTQ)
          console.log('is measured: ', n.isMeasured)
          console.log('prediction value:', n.prediction_value)

          if(n.isCTQ && !n.isMeasured){
            if(n.prediction_value >= 95){
              return "success aal-green aal-green-predicted"
            }

            if(n.prediction_value >= 80 && n.prediction_value < 95){
              return "warning aal-orange aal-orange-predicted"
            }

            if(n.prediction_value < 80){
              return "error aal-pink aal-pink-predicted"
            }
          }

          // if(n.isCTQ && n.isMeasured && n.prediction_value > 0 && n.prediction_value < 100){
          //   return "warning aal-orange aal-orange-predicted"
          // }

          if(n.isMeasured && n.prediction_value == 0){

            var limit = this.trainingLimits.find(x => {
              return x.Name === n.id;
            })

            if(Number(this.currentUnit[n.id]) === Number(limit.Lower)){
              return "success aal-green"
            }


            return "error aal-pink"
          }

          if(n.isMeasured && n.prediction_value == 100){
            return "success aal-green"
          }

          

          if(!n.isCTQ){
            return "secondary aal-grey"
          }
        },

        getChipText(n){

          if(n.isCTQ && !n.isMeasured){
            return n.prediction_value + "% Predicted To Fall In Range";
          }

          if(n.isMeasured && n.prediction_value == 0){
            return "Measured Out Of Range"
          }

          if(n.isMeasured && n.prediction_value == 100){
            return "Measured In Range"
          }

          if(n.isCTQ && n.isMeasured && n.prediction_value > 0 && n.prediction_value < 100){
            return n.prediction_value + "% Predicted To Fall In Range";
          }

          if(!n.isCTQ){
            return "Non CTQ"
          }
        },

        valueSubmitted(e){
          if(e.keyCode == 13){
            this.getNewPredictions()
          }
        },

        getNewPredictions(){

          let memento = {};

          this.invalid = false;
          if(isNaN(this.measuredValue)){

            this.invalid = true;
            return;
          }
          this.fetching = true;
          this.$refs['networkgraph'].setReady(false)

          console.log('sel: ', this.selectedNode)
          var unit = JSON.parse(JSON.stringify(this.currentUnit));

          this.$store.dispatch('getDownStreamNodes', this.selectedNode.id).then(downstreams =>{

            memento.lot = this.currentLot.lot;
            memento.downstreams = JSON.parse(JSON.stringify(downstreams)).map(d => { return { id: d, currentValue: this.getProp(unit, [d]) }});
            memento.id = this.selectedNode.id;
            memento.isCTQ = this.selectedNode.isCTQ;
            memento.old = JSON.parse(JSON.stringify(this.selectedNode));
            memento.currentSameness = this.currentLot.sameness_score;
            memento.currentMeasured = this.currentLot.percentageMeasured;
            memento.currentValue = this.currentMeasurement;
            memento.newValue = this.measuredValue;

            console.log('save details: ', memento);

            //this.$store.dispatch('updateCurrentHistory', memento)

            
            if(this.selectedNode.isCTQ){
            
            }

            var props = this.selectedNode.id.split('.')
            console.log('props', props)

            function setProp(obj, props, val){
              var p = props.shift()
              if(props.length === 0){
                obj[p] = val;
                return
              }
              return setProp(obj[p], props, val)
            }


            console.log('current unit', this.currentUnit)

            
            setProp(unit, props, this.measuredValue)

            // set downstream nodes to NA
            downstreams.forEach(ds => {

              var dprops = ds.split('.');
              console.log('dprops:',  dprops)
              setProp(unit, dprops, 'NA');
              


            })

            
            //this.$store.commit("currentUnitData", unit)
            unit.percentageMeasured = this.chartData.percentageMeasured;

            this.$store.dispatch('getNewPrediction', { unit: unit, node: this.selectedNode, value: this.measuredValue }).then(res => {
              memento.currentSameness = this.currentLot.sameness_score;
              console.log('save details: ', memento);
              this.$store.dispatch('updateCurrentHistory', memento)

              this.fetching = false;
            })

            this.dialog = false;

          })


          
        },

        round(value, precision) {
            var multiplier = Math.pow(10, precision || 0);
            return Math.round(value * multiplier) / multiplier;
        },

        getProp(obj, props){

          var p = props.shift()
          if(props.length == 0){
            return obj[p];
          }
          
          return this.getProp(obj[p], props)              
        },

        nodeSelected(n){
          this.invalid = false;
          this.fetching = true;
          this.dialog = true;
          
          console.log('current lot', this.currentLot)
          this.selectedNode = n;

          this.limits = this.trainingLimits.find(x => {
            return x.Name === n.id;
          })

          console.log('limits:', this.limits)

          var useExisting = this.$store.getters['currentRoute'] == 'Units';
          
          this.$store.dispatch('getNodeData', { lot: this.chartData.lot, name: n.id, useExisting: useExisting, modelid: this.currentModel }).then(res => {
            
            
            this.selectedNode.prediction_value = null;
            this.selectedNode.isCTQ = false;
            this.selectedNode.isMeasured = false;
            
            this.selectedNode.isCTQ = false;
            var prob = this.selectedNode.prediction_value = res['Prob. ' + n.id];
            
            console.log('prob:', prob)
            if(typeof prob === "undefined"){
              
              //console.log('prob undefined')
            }
            else{
                console.log('n:', n)
              //   console.log('node data:', res)
              this.selectedNode.isCTQ = true;
              if(prob == 1 || prob == 0){
                this.selectedNode.isMeasured = true;
              }

              var meas = this.currentUnit[n.id];

              if(meas === 'NA'){
                this.selectedNode.isMeasured = false;
              }
              
              var pr = this.currentLot['Prob. ' + n.id]
              
              this.selectedNode.prediction_value = this.round(pr * 100, 2)
              
            }
            
            var props = n.id.split('.')

            

            
            this.measuredValue = this.getProp(this.currentUnit, props);
            
            if(this.measuredValue && this.measuredValue.toLowerCase() == "na"){
              this.measuredValue = "";
            }

            this.currentMeasurement = this.measuredValue;
            this.fetching = false;

            Vue.nextTick(function(){

              document.getElementById('aalnodeinput').focus()
            }.bind(this))
            
            
            //console.log('res:', this.selectedNode.prediction_value)
          })

          
        },

        
    }
}
</script>


<style>

.toggle-form .checkbox {margin: 1em 0 1em 0; display: block; position: relative; padding-left: 35px; margin-bottom: 12px; cursor: pointer; font-size: 16px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; transition: all 0.3s;}
.toggle-form .checkbox input {position: absolute; opacity: 0; cursor: pointer; height: 25px; width: 25px; left: 0; z-index: 10; transition: all 0.3s;}
.toggle-form .checkbox label {padding: 0; color: #43425D; font-style: normal; font-weight: 300; cursor: pointer;}	
.toggle-form .checkbox .checkmark {position: absolute; top: 0; left: 0; height: 25px; width: 25px; background-color: #eee;}
.toggle-form .checkbox:hover input ~ .checkmark {background-color: #E5E5E5;}
.toggle-form .checkbox input:checked ~ .checkmark {background-color: #69BFBA;}
.toggle-form .checkbox .checkmark:after {content: ""; position: absolute; display: none;}
.toggle-form .checkbox input:checked ~ .checkmark:after {display: block;}
.toggle-form .checkbox .checkmark:after {left: 10px; top: 7px; width: 5px; height: 10px; border: solid white; border-width: 0 3px 3px 0; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg);}

.aal-status {display: flex; flex-direction: column; justify-content: center; align-items: stretch; width: 100%; border-radius: 8px 8px 0 0;}
.aal-status .aal-inner {padding: 10px 20px; border: none; display: flex; justify-content: center; align-items: stretch; width: 100%; border-radius: 6px 6px 0 0;}
.aal-status .aal-inner span {margin: 0; color: #ffffff; font-weight: 500; font-size: 1em;}
.aal-status.aal-green {border: 3px solid #54D968;}
.aal-status.aal-green-predicted .aal-inner {background: #ffffff;}
.aal-status.aal-green-predicted span {color: #54D968;}
.aal-status.aal-orange {border: 3px solid #FFC454;}
.aal-status.aal-orange-predicted .aal-inner {background: #ffffff;}
.aal-status.aal-orange-predicted span {color: #FFC454;}
.aal-status.error {margin-top: 0; margin-left: 0;}
.aal-status.aal-pink {border: 3px solid #FF548F; background: #FF548F !important;}
.aal-status.aal-pink span {font-size: 1.2em !important;}
.aal-status.aal-pink-predicted .aal-inner {background: #ffffff;}
.aal-status.aal-pink-predicted span {color: #FF548F;}
.aal-status.aal-grey {border: 3px solid #d5d5d5 !important; border-color: #d5d5d5; background: #d5d5d5 !important;}

.aal-step-detail {display: flex; flex-direction: row; justify-content: flex-start; align-content: stretch; background: #e5e5e5; overflow: hidden; border-top: 2px solid #cccccc; border-bottom: 2px solid #cccccc;}
.aal-step-detail h5 {font-size: 1em; padding: 10px 20px; align-self: center; color: #43425D;}
.aal-step-detail span {font-size: 1em; padding: 10px 20px; display: flex; align-self: center; justify-content: flex-start; flex-grow: 1;}

.aal-step-value {padding: 15px 20px 5px 20px; background: #f5f5f5; border-radius: 0 0 8px 8px; border-bottom: 3px solid #cccccc; overflow: hidden;}
.aal-step-value h5 {margin: 0 0 5px 0; font-size: 0.9em; color: #43425D;}
.aal-step-value input#aalnodeinput {padding: 10px 20px; height: 50px; max-height: 50px;}

.aal-acceptable {padding: 10px 20px 15px 20px; background: #f5f5f5; border-radius: 8px; border-bottom: 3px solid #cccccc; overflow: hidden;}
.aal-acceptable h5 {margin-bottom: 10px; font-size: 1em;}
.aal-acceptable .aal-lower-upper {display: flex; flex-direction: row; justify-content: center; align-content: stretch;}
.aal-acceptable .aal-lower-upper span {display: flex; flex-direction: row; justify-content: flex-start; align-content: center; flex-grow: 1; align-self: center;}
.aal-acceptable .aal-lower-upper span i {margin-right: 0.25em; line-height: 1; align-self: center;}

.aal-influencer {padding: 0 0 10px 0; background: #f5f5f5; border-radius: 8px; border-bottom: 3px solid #cccccc; overflow: hidden;}
.aal-influencer h5 {margin: 0; padding: 15px 20px 20px 20px; font-size: 1em; color: #43425D;}
.aal-influencer h5::after {margin: 5px 0 0 0; content:''; width: 50px; height: 2px; background: #43425D; display: block;}
.aal-influencer .aal-list ul {margin: 0 10px; padding: 0; list-style: none;}
.aal-influencer .aal-list ul li {margin: 0; padding: 8px 20px; list-style: none; font-weight: 100;}
.aal-influencer .aal-list ul li b {font-weight: 300;}
.aal-influencer .aal-list ul li:nth-child(odd) {background: #e5e5e5;}

.invalid{
  color: #FF548F;
  font-size: 0.8em;
}
  
</style>