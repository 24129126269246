<template>
    
    <div style="max-height: 627px;overflow: auto;">    
        <ul class="mr-3">
            <li v-if="!currentHistory || currentHistory.length === 0" class="aal-no-history">No current change history</li>
            <li v-if="currentHistory && currentHistory.length > 0" style="margin-bottom: 5px;" class="aal-sameness"><span class="headline">Initial Sameness Score:</span> <span class="score">{{initialSameness}}</span></li>
            <li v-for="(item, idx) in currentHistory" :key="idx" @mouseover="mouseOver(item)" @mouseleave="mouseLeave(item)" class="px-3 py-3 aal-item" :class="{ 'info': item === highlighted, 'elevation-4': item === highlighted}">
                <h5>{{item.id}}</h5>
                <span class="aal-changed-from"><i>Changed From:</i> {{ item.currentValue || 'empty' }} to {{ item.newValue || 'empty' }} </span>
                <span class="aal-changed-sameness"><i>Sameness Score:</i> {{ formatSameness(item.currentSameness) }}</span>
            </li>
        </ul>
    </div>

</template>

<script>
export default {
    
    name: 'HistoryList',

    data(){
        return {
            highlighted: null,
            clicked: null
        }
    },

    methods: {
        formatSameness(sm){
            return sm.toFixed(2) + "%"
        },

        mouseOver(item){
            this.highlighted = item;
            this.$emit('nodehover', item)
        },

        mouseLeave(item){
            if(this.clicked === item){
                return;
            }
            this.highlighted = null;
            this.$emit('nodeleave', item)
        },

        
    },

    computed: {

        currentHistory(){
            return this.$store.getters['currentHistory']
        },

        initialSameness(){
            return this.$store.getters['initialSameness'].toFixed(2) + "%"
        },

        
    }

}
</script>

<style>

.aal-change-history ul li.aal-sameness {margin: 0 0 10px 0 !important; padding: 10px; font-weight: 500; text-align: center; display: flex; flex-direction: column; line-height: 1; border-radius: 0.25em; border-bottom: 5px solid #76D6FF; background: #f5f5f5;}
.aal-change-history ul li.aal-sameness span.headline {margin: 0; padding: 0; font-weight: 300; font-size: 0.8em!important; line-height: 1; color: #43425D;}
.aal-change-history ul li.aal-sameness span.score {margin: 0; padding: 0; font-weight: 700; font-size: 1.5em; line-height: 1; color: #43425D;}

.aal-change-history ul li.aal-no-history {font-size: 0.9em; line-height: 1;}

.aal-change-history h5 {margin: 0 0 10px 0; padding: 0 0 5px 0; color: #43425D;}
.aal-change-history h5::after {margin: 5px 0 0 0; content:''; width: 50px; height: 2px; background: #43425D; display: block;}
.aal-change-history span {margin: 10px 0 5px 0; padding: 0; font-size: 0.9em; font-weight: 500; line-height: 1; display: block; color: #43425D;}
.aal-change-history span i {margin: 0; padding: 0; font-style: normal; font-size: 0.8em; font-weight: 300; line-height: 1; color: #43425D;}

.aal-change-history span.aal-changed-from {margin: 0 0 5px 0; padding: 7px 0 7px 10px; border-left: 5px solid #43425D; background: #ffffff; border-radius: 0.25em;}
.aal-change-history span.aal-changed-sameness {border-left: 5px solid #76D6FF; padding: 7px 0 7px 10px; background: #ffffff; border-radius: 0.25em;}

.aal-change-history ul li.aal-item {margin-bottom: 5px; border-radius: 0.25em;}
.aal-change-history ul li.aal-item i {margin-bottom: 5px; display: block;}
.aal-change-history ul li.aal-item:nth-child(even) {background: #e5e5e5;}
.aal-change-history ul li.aal-item:nth-child(odd) {background: #f5f5f5;}

</style>