<template>

    
    <v-container class="px-5 grey lighten-4" fluid>
        <h2 class="mb-4 unit-title">Welcome</h2>

        <v-card>
            
            <div class="mx-3 py-4">
                <p>Upload your data to your customised AAL model and recieve your prediction results.</p>
                    
                <p>You can also view previous results.</p> 
            </div>
                
            
                
            
        </v-card>
        
    </v-container>
    
</template>

<script>


export default {

    mounted(){
        this.$store.commit('currentRoute', 'Welcome')
        //this.$store.dispatch('getTrainingLimits')
       
    },
}
</script>

<style>

</style>