
<template>


<v-container class="grey lighten-4" fluid>
    
    <v-layout row class="mb-4 mx-4" wrap>
        <v-flex xs12 sm8>
            <h2 class=" unit-title">Current Sameness Scores <span>- {{ modelTitle }}</span></h2>
        </v-flex>
        <v-flex xs12 sm4 class="text-xs-right">
            <button class="mt-0 mr-0 text-none btn w" @click="refreshList" >Refresh Data <v-icon>loop</v-icon></button>
        </v-flex>

    </v-layout>


        <v-card class="mb-5 px-3 grey lighten-4 elevation-0 ml-2 mr-2" color="">
                
            <transition-group name="fade">
                <div key="loading" v-if="loading">
                    <v-container v-if="loading">
                        <v-layout align-center>
                            <v-subheader>Loading data please wait...</v-subheader>
                            <v-progress-circular indeterminate color="primary" v-if="loading"></v-progress-circular>
                        </v-layout>
                    </v-container>
                </div>
                <div v-if="!loading" key="table">
                    <results-table v-if="!viewDetails" @itemselected="itemSelected" @itemhover="preloadItem" :existing="true"></results-table>
                </div>
                <div v-if="viewDetails && selectedItem && !loading" key="detail">
                    <detail-view v-if="viewDetails && selectedItem && !loading"></detail-view>
                </div>
                <div v-if="viewDetails" key="button">
                    <v-btn color="primary" @click="goBack" v-if="viewDetails">Back</v-btn>
                </div>
            </transition-group>
            
        </v-card>
   
    
</v-container>


</template>

<script>

import ResultsTable from '../components/ResultsTable.vue'
import DetailView from './Detail.vue'

export default {


    name: 'Units',

    components: { 
        ResultsTable,
        DetailView
     },

    data() {
        return {
            viewDetails: false,
            selectedItem: null,
            loading: true
        }
    },


    created(){
        //this.$store.dispatch('getModelData', { id: '3j'})
    },


    mounted(){
        this.$store.commit('currentRoute', 'Units')
        this.loading = true
        this.$store.dispatch('getExistingUnits').then(()=>{
            this.$store.commit('currentUnitData', null)
            this.loading = false;
            
            
        })
        this.$store.commit('currentRoute', 'Units')
    },

    computed: {

        modelTitle(){
            return this.$store.getters['currentModelTitle']
        }
    },


    methods: {

        preloadItem(item){
            // console.log('preload', item)
            // if(item && item.lot){
            //     this.$store.dispatch('getUnit', item.lot).then(()=>{
            //             this.$store.commit('currentLot', item);
                        
            //     })
            // }
        },

        itemSelected(item){
            
            this.selectedItem = item;

            //console.log('item selected', item)
            if(item && item.lot){
                this.$store.dispatch('getUnit', item.lot).then(()=>{
                        this.$store.commit('currentLot', item);
                        this.$store.commit('initialSameness', item.sameness_score)
                         //this.viewDetails = true;
                        this.$emit('scoreselected', item);
                        this.$router.push({ name: 'unitdetail' })
                })
            
            
           
            
            }
            
        },

        goBack(){
            this.viewDetails = false;
        }, 
        
        
        refreshList(){
            this.loading = true;
          this.$store.dispatch('getExistingUnits').then(res => {
              this.loading = false;
          })
        },
        
    }
}


</script>


<style>
    button.btn.w {padding: 0 1em; color: rgb(67,66,93); background: rgba(255,255,255,1); border: none; height: 50px; line-height: 50px; cursor: pointer; font-weight: 400; text-transform: none; font-size: 0.9em; transition: 0.3s ease-in-out;}
    button.btn.w:hover {color: rgb(67,66,93); background: rgba(255,255,255,0.6); box-shadow: 0 0 10px rgba(67,66,93,0.05);}
    button.btn.w span {font-weight: 600;}
    button.btn.w i {color: rgb(67,66,93); align-self: center;}

    .fade-enter-active, .fade-leave-active {
        transition-property: opacity;
        transition-duration: .2s;
    }

    .fade-enter-active {
        transition-delay: .2s;
    }

    .fade-enter, .fade-leave-active {
        opacity: 0
    }
</style>
