
<template>
    
    <upload-btn @file-update="update" :title="labelText" style="padding-left: 0px;" color="secondary"></upload-btn>
    
</template>


<script>

import UploadButton from 'vuetify-upload-button';
import axios from 'axios';

export default {
    name: 'FileUpload',

    components: {
        'upload-btn': UploadButton
    },

    props: ['label'],

    data() {
        return {
            labelText: this.label || 'Choose file'
        }
    },

    methods: {

        uploadFile(file){


            if(file){

                const formData = new FormData();
                formData.append('file', file);

                this.$store.dispatch('uploadClientData', formData).then(res => {
                    this.$emit('fileuploaded', res)
                })

                // try{
                //     await axios.post('http://localhost:3000/predict/upload', formData)
                //     console.log('uploaded')
                //     this.$emit('fileuploaded')
                // }
                // catch(err){

                // }

            }
            
        },

        readFile(file){

            if(file){

                //console.log(file)
                let fileData = null;

                const reader = new FileReader();

                reader.onloadend = () => {
                    fileData = reader.result;
                    //console.log(fileData)

                    this.$emit('fileuploaded', fileData)
                };

                reader.readAsText(file)
                
            }
        },

        update(file){

            if(file){
                this.$emit('fileuploaded', file);
            }
            
        }
    }
}
</script>
