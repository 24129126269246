<template>
    
    <div>

        <ul class="mr-3 aal-legend-list" style="">
            <li v-for="(item, idx) in keys" :key="idx" class="mr-3">
                <svg height="20" width="20" class="mr-2" transform="translate(2,5)">
                    <circle r="10"  transform="translate(10, 10)" :fill="getColor(item.border)" :style="getStroke(item)">
                    </circle>
                    <circle r="9" transform="translate(10, 10)" :fill="getColor('white')" v-if="!getStroke(item)">

                    </circle>
                    <circle transform="translate(10, 10)" r="7" :fill="getColor(item.fill)" ></circle>
                </svg> 
                {{item.text}}
            </li>
        </ul>

    </div>
</template>

<script>

import utils from '../utils'

export default {
    name: 'NetworkKey',

    data(){
        return {
            
            //gba(67,66,93,0.25)
            keys:[
                { tag: 'in', text: 'Measured in range', fill: 'green', border: 'green'},
                { tag: 'out', text: 'Measured out of range', fill: 'pink', border: 'pink'},
                { tag: 'none', text: 'Non CTQ measured', fill: 'darkgrey', border: 'darkgrey'},
                { tag: 'predictedin', text: 'Predicted in range', fill: 'white', border: 'green'},
                { tag: 'predictedout', text: 'Predicted out of range', fill: 'white', border: 'pink'},
                { tag: 'none', text: 'Non CTQ unmeasured', fill: 'white', border: 'darkgrey'},
                { tag: 'predictedborder', text: 'Predicted borderline', fill: 'white', border: 'orange'},
                { tag: 'lastapplied', text: 'Last modified node', fill: 'purple', border: 'white'},
                { tag: 'selectednode', text: 'Selected node', fill: 'skyblue', border: 'white'},
                { tag: 'selectedctq', text: 'Selected CTQ', fill: 'white', border: 'purple'},
                
            ],

            
        }
    },

    methods:{

        getColor(col){
            return utils.getColor(col)
        },

        getStroke(item){
            if(item.tag == 'selectedctq'){
                return "stroke-width: 14;"
            }
        }
    }
    
}
</script>

<style>

ul.aal-legend-list {list-style: none; font-size: 0.7em; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;}
ul.aal-legend-list li {flex-grow: 1; flex-basis: 30%;}

@media screen and (max-width: 900px) {

ul.aal-legend-list li {flex-grow: 1; flex-basis: 40%;}

}

@media screen and (max-width: 640px) {

ul.aal-legend-list li {flex-grow: 1; flex-basis: 100%;}

}

</style>
