import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'
import { Ripple } from 'vuetify/lib/directives'


Vue.use(Vuetify, {
  iconfont: 'md',
  directives: {
    Ripple
  },
  theme: {
    primary: '#43425D', // purple
    secondary: '#69BFBA', // teal
    accent: '#E5E5E5', // dark grey
    error: '#FF548F', // pink
    info: '#F5F5F5', // field / button grey
    success: '#54D968', // green
    warning: '#FFC454' // orange
  }
})
