
<template>

    <div v-if="selectedItem">
        <v-layout row wrap>
            <div class="mobile scores mx-2 mb-2">
                <div class="item">
                    <h5 class="px-4"><span>Sameness</span> <span>{{formatScore(selectedItem.sameness_score) }}%</span></h5>
                    <div class="bar-score bar-blue mx-4">
                        <span :style="{ 'width' : selectedItem.sameness_score + '%' }"></span>
                    </div>
                </div>
                <div class="item">
                    <h5 class="px-4"><span>CTQs Measured</span> <span>{{formatScore(selectedItem.percentageMeasured) }}%</span></h5>
                    <div class="bar-score bar-teal mx-4">
                        <span :style="{ 'width' : selectedItem.percentageMeasured + '%' }"></span>
                    </div>
                </div>
            </div>
        </v-layout>
        <v-layout row wrap>
            
            <v-flex xs12 sm12 md12 lg9 xl9>
                <v-layout row wrap>
                    <v-flex>
                    <v-card class="mr-2 mt-2 mb-2 ml-2 elevation-1">
                        <results-chart v-if="selectedItem" :item="selectedItem" ref="resultsChart"></results-chart>
                    </v-card>
                    </v-flex>
                </v-layout>
                <v-layout>
                    <v-flex>
                        <v-card class="mr-2 mt-2 mb-3 ml-2 elevation-1"> 
                            <h4 class="pt-3 ml-3 mb-2 unit-subtitle">Legend</h4>
                            <div class="ml-3 aal-legend">
                                <network-key></network-key>
                            </div>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md12 lg3 xl3>

                <v-layout row wrap>
                    <div class="desktop scores mr-2 ml-2 mt-2 mb-2 px-4 py-3">
                        <div class="item">
                            <h5 class="unit-subtitle mb-3">Sameness Score</h5>
                            <sameness-gauge v-if="selectedItem && showSameness" :item="selectedItem"></sameness-gauge>
                        </div>
                        <div class="item">
                            <h5 class="unit-subtitle mb-3">CTQs Measured</h5>
                            <sameness-gauge v-if="selectedItem && showSameness" :item="selectedItem" :completeness="true" :colorlist='[ "#69bfba", "#E5E5E5"]'></sameness-gauge>
                        </div>
                    </div>
                </v-layout>

                <v-layout row wrap>
                     <v-flex>

                        <v-card class="mr-2 mt-2 ml-2 elevation-1"> 
                            <h4 class="pt-3 ml-3 mb-2 unit-subtitle">Change History</h4>
                            <div class="ml-3 aal-change-history">
                                <history-list @nodehover="nodeHover" @nodeleave="nodeLeave"></history-list>
                            </div>
                        </v-card>
                    </v-flex>
                </v-layout>
               
            </v-flex>
            

        </v-layout>
          
    </div>

</template>



<script>

import ResultsChart from '../components/ResultsChart.vue'
import SamenessGauge from '../components/SamenessGauge.vue'
import NetworkKey from '../components/NetworkKey.vue'
import CtqList from '../components/CTQList.vue'
import HistoryList from '../components/HistoryList.vue'
import utils from '../utils'


export default {
    components:{
        ResultsChart,
        SamenessGauge,
        NetworkKey,
        CtqList,
        HistoryList
    },

    mounted(){
      this.$store.dispatch('clearCurrentHistory');
      
    },

    data(){
        return {
           showSameness: true,
           
        }
    },

    methods: {

        nodeHover(item){
            this.$refs.resultsChart.nodeHover(item);
        },

        nodeLeave(item){
            this.$refs.resultsChart.nodeLeave(null);
        },

        round(value, precision) {
            var multiplier = Math.pow(10, precision || 0);
            return Math.round(value * multiplier) / multiplier;
        },

        goBack(){
            //this.selectedItem = null
            this.$emit('goback')

        },

        formatScore(d){
            //console.log('text score', self.samenessScore)
            if(d == 100){

                return Math.floor(d)
            }
            else{
                
                return utils.round(d, 2)
            }
        }
    },

    watch:{
        selectedItem(){
            this.showSameness = false;
            this.showSameness = true;
        }
    },

    computed:{
        selectedItem(){
            
            return this.$store.getters['currentLot']
        },

        

        
    }

}

</script>

<style>

.desktop.scores {display: flex; flex-direction: row; justify-content: space-between; width: 100%; box-shadow: 0px 0 3px rgba(0,0,0,0.1); text-decoration: none; background: #ffffff;}
.desktop.scores .item {flex-grow: 1;}

.mobile.scores {display: none; flex-direction: row; justify-content: flex-start; width: 100%; box-shadow: 0px 0 3px rgba(0,0,0,0.1); text-decoration: none; background: #ffffff;}
.mobile.scores .item {display: flex; flex-direction: row; justify-content: center; align-items: center; align-content: center; flex-grow:  1; height: 100px; width: 100%;}
.mobile.scores .item h5 {width: 30%; font-size: 1em; line-height: 1.2; display: flex; flex-direction: column; color: #43425D;}
.mobile.scores .item h5 span:first-of-type {font-weight: 700;}
.mobile.scores .bar-score {width: 70%; display: flex; align-content: center; align-items: stretch; height: 60px; background: #f5f5f5;}
.mobile.scores .bar-score.bar-blue {width: 100%;}
.mobile.scores .bar-score.bar-blue span {margin: 0; padding: 0; content: ''; font-weight: 500; display: flex; justify-content: flex-start; align-items: center; background: rgb(118, 214, 255); height: 60px; color: #ffffff; font-size: 1.5em; text-shadow: 0px 0 5px rgba(0,0,0, 0.4);}
.mobile.scores .bar-score.bar-teal {width: 100%;}
.mobile.scores .bar-score.bar-teal span {margin: 0; padding: 0; content: ''; font-weight: 500; display: flex; justify-content: flex-start; align-items: center; background: rgb(105, 191, 186); height: 60px; color: #ffffff; font-size: 1.5em; text-shadow: 0px 0 5px rgba(0,0,0, 0.4);}

.aal-legend {margin: 0; padding: 5px 0 20px 0;}
.aal-legend ul {margin: 0; padding: 0;}
.aal-legend ul li {margin: 0; padding: 3px 0; list-style: none; font-size: 1.2em;}

.aal-change-history {margin: 0; padding: 5px 0 20px 0;}
.aal-change-history ul {margin: 0; padding: 0;}
.aal-change-history ul li {margin: 0; padding: 3px 0; list-style: none; font-size: 1.2em;}


@media screen and (max-width: 1260px) {
	.mobile.scores {display: flex;}
}

@media screen and (max-width: 900px) {
	.mobile.scores {flex-direction: column;}
}

</style>