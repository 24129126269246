<template>
  
  <v-card class="mt-3 mr-3">   

    <div class="pb-4 pt-4">
      <v-img :src="modeldata.imageurl" max-height="250px"  :contain="true" class="px-4"></v-img>
    </div>        
    
    <div class="grey lighten-4 pb-3 pl-3 pr-3 pt-3">

      <v-card-title primary-title>
      <div>
        <h3 class="headline mb-0">{{modeldata.title}}</h3>
        <p>{{modeldata.summary}}</p>
      </div>
    </v-card-title>

    </div>

    <div class="itemFooter">
        <div class="button reverse">
          <button class="btn" @click="modelSelected"><span>View Units</span> <i class="fas fa-angle-down"></i></button>
        </div>
      <!-- <v-btn class="btn" @click="modelSelected">View units</v-btn> -->
    </div>
    

  </v-card>

</template>

<script>
export default {

    name: 'ModelSummary',
    props: ['model'],

    data(){
        return {
            modeldata: this.model
        }
    },

    methods: {

        modelSelected(){
            this.$emit('modelselected', this.modeldata)
        }
    }

}
</script>

<style>



</style>