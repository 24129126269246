
<template>
<v-card>

<v-container fluid class="mx-0 my-0 grey lighten-5">

  <v-layout row wrap class="mt-3">
    <v-flex xs9 class="search-form">
      <!-- <v-text-field v-model="searchText" @keyup.native="searchInput" label="Search by unit" class="pr-3 search-field"></v-text-field> -->
      <label for="search" class="search-label" :class="{ 'active': labelActive || searchText }">Search by unit</label>
      <input v-model="searchText" @keyup="searchInput" type="text" name="search" class="search-field" @focus="labelActive = true"
  @blur="labelActive = false">
    </v-flex>
    <v-flex xs3>
      <v-btn class="mt-0 primary text-none" @click="updateSearch(searchText)" flat>Search</v-btn>
      <v-btn class="mt-0 text-none" @click="clearSearch" :disabled="!searchText">Clear</v-btn>
    </v-flex>
  </v-layout>

  <v-layout row wrap class="search-form">
    <v-flex>
      <div class="checkbox">
		    <input id="exclude_measured" v-model="excludeComplete" name="marketing" value="Exclued Measured" type="checkbox" @change="excludeUpdated">
        <label for="exclude_measured">Exclude fully measured units</label>
        <span class="checkmark"></span>
      </div>
    </v-flex>
  </v-layout>
  
</v-container>

<v-data-table
    :headers="headers"
    :items="predictionData"
    :disable-initial-sort="true"
    class="elevation-1"
    :rows-per-page-items='[10,25,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
  >
    <template v-slot:items="props">
      <td>{{ props.item.lot }}</td>

      <td>
        <div style="width: 50px; margin-top: 10px; margin-left: 10px; cursor: pointer;" :title="'Sameness score: ' + props.item.sameness_score">

          <a @click="itemSelected(props.item)" >
            <sameness-gauge v-if="showSameness" ref="samenessgauge" :item="props.item" :small="true"></sameness-gauge>
          </a>
        </div>
      </td>
      
      <!-- <td :class="trafficLight(props.item.sameness_score)">{{ props.item.sameness_score }}</td> -->
      <td>{{ props.item.percentageMeasured }} %</td>
      
      <!-- <td>{{props.item.modelid}}</td> -->
      <td>
        {{props.item.filename}}
      </td>
      <td>
        {{props.item.dateuploaded | formatDate}}
      </td>
       <!-- <td>{{props.item.username}}</td> -->

      <td v-if="loading === props.item.lot">
        <v-progress-circular indeterminate color="primary" class="ml-4 pl-4"></v-progress-circular>
      </td>
      <td class="special-icon" v-if="loading !== props.item.lot" @mouseover="itemHover(props.item)">
          <!-- <a><v-icon class="error--text" @click="itemSelected(props.item)">scatter_plot</v-icon></a> -->
          
          <button class="special-icon-button lighten" @click="itemSelected(props.item)"><span>View</span><v-icon>chevron_right</v-icon></button>
      </td>
    </template>
  </v-data-table>

  
  </v-card>
    
</template>

<script>
import SamenessGauge from './SamenessGauge.vue'
import moment from 'moment'
import { setTimeout } from 'timers';

export default {
    name: 'ResultsTable',
    props: ['existing'],

    components: {
      SamenessGauge
    },

    data () {
      return {

        showExisting: this.existing,
        
        searchText: '',
        searchValue: '',
        showSameness: true,
        excludeComplete: false,
        loading: '', 
        labelActive: false
        
      }
    },

    watch: {

      'searchText': function(n,o){
        
      }
    },

    filters:{
      formatDate(d){
        if(d){

          return moment(d).format('DD/MM/YYYY HH:mm')
        }
        return '';
      }
    },

    mounted(){

      if(this.$store.getters['unitSearch']){
        this.searchValue = this.searchText = this.$store.getters['unitSearch']
      }

      this.excludeComplete = this.$store.getters['excludeComplete'];
      
    },

    computed: {

      headers(){

        var headers =[
          { text: 'Unit', align: 'left', sortable: true,value: 'lot', class: 'tableHeaders'},
          { text: 'Sameness Score', value: 'sameness_score', sortable: true, class: 'tableHeaders'},
          // { text: 'Sameness score', value: 'sameness_score', sortable: true, class: 'tableHeaders' },
          { text: 'CTQs Measured', value: 'percentageMeasured', sortable: true, class: 'tableHeaders' },
          // { text: 'Model', value: 'modelid', sortable: false, class: 'tableHeaders'},
          { text: 'Related file', value: 'filename', sortable: true, class: 'tableHeaders' },
          { text: 'Uploaded on', value: 'dateuploaded', sortable: true, class: 'tableHeaders' },
          // { text: 'Uploaded by', value: 'username', sortable: true, class: 'tableHeaders' },
          { text: '', value: 'sameness_score', sortable: false, class: 'tableHeaders' }
        ];

        // if(!this.showExisting){
        //   headers = headers.filter(h => {
        //     return h.value != 'filename' && h.value != 'dateuploaded'
        //   })
        // }

        return headers
      },

      predictionData(){

        var data = this.$store.getters["predictionData"]
        if(this.existing){
          data = this.$store.getters["existingPredictions"];
        }
        
        if(!data || !data[this.$store.getters['currentModel']]){
          return [];
        }
        return data[this.$store.getters['currentModel']].filter(u => {
            
            if(this.excludeComplete && u.percentageMeasured === 100){
              return false;
            }
            return this.searchValue == '' || u.lot.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1
            
        })
      }
    },

    methods: {

  

        excludeUpdated(){
          this.$store.commit('excludeComplete', this.excludeComplete);
        },

        clearSearch(){
          this.searchText = '';
          this.searchValue = '';
          this.excludeComplete = false;
          this.$store.commit('unitSearch', '');
          this.$store.commit('excludeComplete', false);
        },

        searchInput(e){
          if(e.keyCode == 13){
            this.updateSearch(this.searchText)
          }
        },

        updateSearch(n){

          
          this.searchValue = n;
          this.$store.commit('unitSearch', n);
          
        
        },

        redrawGauge(e){

          
          if(this.$refs.samenessgauge){
            this.$refs.samenessgauge.initd3();
          }
         
        },

        itemSelected(item){

            
            this.loading = item.lot;
            //console.log('item selected', item)
            this.$emit('itemselected', item)

            setTimeout(()=>{
              this.loading = ''
            }, 5000)
        },

        itemHover(item){
          
          this.$emit('itemhover', item)
        },

        

        trafficLight(score){
          // if(score < 96){
          //   return 'redlight'
          // }
          // if(score < 97){
          //   return 'amberlight';
          // }
          // return 'greenlight'
        }
    },
}
</script>


<style>


.v-text-field__slot {height: 50px; background: #ffffff; border-bottom: none;}
.v-text-field {padding-top: 0 !important; margin-top: 0 !important;}
.v-text-field label {line-height: 50px; height: 50px; padding-left: 20px;}
.v-input .v-label {line-height: 50px; height: 50px;}

.search-form {position: relative;}
.search-form .search-label {padding: 0 0 0 20px; color: #43425D; font-size: 0.8em; position: absolute; top: 15px; transition: all 0.3s;}
.search-form .search-label.active {padding: 0 0 0 10px; top: 3px; font-size: 0.6em; color: #999999;}
.search-form .search-field {padding: 10px 10px 2px 10px; line-height: 50px; height: 50px; width: 100%; font-size: 0.8em; background: #ffffff; border-bottom: 1px solid #D5D5D5; transition: all 0.3s;}
.search-form .search-field:hover {border-bottom: 1px solid #43425D; box-shadow: 0 0 5px rgba(230,230,230,1);}

.search-form .checkbox {margin: 1em 0 1em 0; display: block; position: relative; padding-left: 35px; margin-bottom: 12px; cursor: pointer; font-size: 16px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; transition: all 0.3s;}
.search-form .checkbox input {position: absolute; opacity: 0; cursor: pointer; height: 25px; width: 25px; left: 0; z-index: 10; transition: all 0.3s;}
.search-form .checkbox label {padding: 0; color: #43425D; font-style: normal; font-weight: 100; cursor: pointer;}	
.search-form .checkbox .checkmark {position: absolute; top: 0; left: 0; height: 25px; width: 25px; background-color: #eee;}
.search-form .checkbox:hover input ~ .checkmark {background-color: #E5E5E5;}
.search-form .checkbox input:checked ~ .checkmark {background-color: #69BFBA;}
.search-form .checkbox .checkmark:after {content: ""; position: absolute; display: none;}
.search-form .checkbox input:checked ~ .checkmark:after {display: block;}
.search-form .checkbox .checkmark:after {left: 10px; top: 7px; width: 5px; height: 10px; border: solid white; border-width: 0 3px 3px 0; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg);}

.greenlight{
  background-color:rgba(84,217,104,0.5);
}

.amberlight{
  background-color: rgba(255,196,84,0.5);
}

.redlight{
  background-color: rgba(255,84,143,0.5);
}

</style>
