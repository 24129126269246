import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import About from './views/About.vue'
import Results from './views/Results.vue'
import Stepper from './views/Stepper.vue'
import UploadedFiles from './views/UploadedFiles.vue'
import Welcome from './views/Welcome.vue'
import TestDetail from './views/TestDetail.vue'
import Units from './views/Units.vue'
import UnitDetail from './views/UnitDetail.vue'
import SignIn from './views/SignIn'
import ModelDashboard from './views/ModelDashboard.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'welcome',
      component: Welcome,
      meta: { requiresAuth: true }
    },
    {
      path: '/SignIn',
      name: 'signin',
      component: SignIn,
      meta: { requiresAuth: false }
    },
    {
      path: '/upload',
      name: 'upload',
      component: Stepper,
      meta: { requiresAuth: true }
    },

    {
      path: '/files',
      name: 'uploadedFiles',
      component: UploadedFiles,
      meta: { requiresAuth: true }
    },

    {
      path: '/testdetail',
      name: 'testdetail',
      component: TestDetail,
      meta: { requiresAuth: true }
    },
    
    {
      path: '/dashboard',
      name: 'modeldashboard',
      component: ModelDashboard,
      meta: { requiresAuth: true }
    },

    {
        path: '/results',
        name: 'results',
        component: Results,
        meta: { requiresAuth: true }
    },
    {
      path: '/units',
      name: 'units',
      component: Units,
      meta: { requiresAuth: true }
    },
    {
      path: '/unitdetail',
      name: 'unitdetail',
      component: UnitDetail,
      meta: { requiresAuth: true }
    },
    
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: About,//() => import(/* webpackChunkName: "about" */ './views/About.vue'),
      meta: { requiresAuth: true }
    }
  ]
})


router.beforeResolve(async(to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser();
      next();
    } catch (e) {
      next({
        path: "/SignIn",
        query: {
          redirect: to.fullPath
        }
      });
    }
  }
  next();

})


export default router;
