<template>
  <div>

      <v-container fluid>
        
        <v-select
          :items="models"
          label="Choose a model"
          v-model="selectedModel"
        ></v-select>
      </v-container>
    
      <v-container v-if="selectedModel">
        <h2 class="mb-3">Submit data to the model for processing</h2>
       
        <v-progress-circular indeterminate color="primary" v-if="uploading"></v-progress-circular>
        
        <div v-if="!uploading" class="mb-4">
          <p>1. Choose a file to submit to the model</p>
          <file-upload @fileuploaded="gotFile"></file-upload>
        </div>

        <div v-if="!uploading" class="mb-4">
          <p>2. Review the file contents</p>
          <file-table :filedata="fileData"></file-table>
        </div>

        <div v-if="!uploading">
          <p>3. Click the 'submit to model' button</p>
          <v-btn color="success" @click="submitToModel">Submit to model</v-btn>
        </div>

      </v-container>

  </div>
  
</template>

<script>
  import Splash from '../components/Splash'
  import FileUpload from '../components/FileUpload.vue'
  import FileTable from '../components/FileTable.vue'
  import Papa from 'papaparse'

  export default {
    components: {
      Splash,
      FileUpload,
      FileTable
    },

    data() {

        return {
          selectedModel: null,
          models: ['Height Prediction'],
          fileProcessed: false,
          uploadComplete: false,
          uploading: false,
          fileData: [],
          fileInfo: null
        }
    },

    mounted(){
        this.$store.commit('currentRoute', 'Submit data')
    },

    methods: {

        uploadFile(file){

            if(file){

                const formData = new FormData();
                formData.append('file', file);

                return this.$store.dispatch('uploadClientData', formData);
    
            }
        },

        showFile(results){
          //console.log(results.data)
          this.fileData = results.data;
          this.fileProcessed = true;
        },

        submitToModel(){

            if(this.fileInfo){
              this.uploading = true;
              this.uploadFile(this.fileInfo).then(() => {
                this.uploading = false;
                this.$router.push({ name: 'results' })
              })
            }
            
        },

        gotFile(file){
            
            this.fileInfo = file;
            this.uploading = true;
            const self = this;
            let fileData = null;
            Papa.parse(file, {
              header: true,
              complete: (res) => {
                
                self.uploading = false;
                self.showFile(res);
              }
            })

            
        }
    }
  }
</script>
