import axios from 'axios'
import { Auth } from 'aws-amplify'


function getAxiosConfig(){
    return Auth.currentSession().then(data =>{

        //console.log('tokens: ',  data)
        
        var config = {
            headers: { Authorization: `Bearer ${data.accessToken.jwtToken}`, Access: `ID ${data.idToken.jwtToken}`}
        }
        
        return config;
    })
}

export default {


    get: function(route){
        
        return getAxiosConfig().then(config => {

            return axios.get(route, config).then(results => {
        
                return results;
            })
       })
        

    },


    post: function(route, data){

        return getAxiosConfig().then(config => {

            return axios.post(route, data, config).then(results => {
                return results;
            })
        })
    }

}