<template>
  <v-app>

      <v-navigation-drawer id="aalsidenav"
      v-if="showDrawer"
      class=""
      v-model="drawer"
      dark
      :hide-overlay="false"
      clipped
      fixed
      :mini-variant="true"
      :permanent="true"
      app
    >
    
      <v-list class="">

        <v-list-tile>
          <v-list-tile-action>
            <v-img :src="require('./assets/aal.svg')" :contain="true" max-height="50"></v-img>
          </v-list-tile-action>
        </v-list-tile>

        <v-list-tile @click="signOut">
          <v-list-tile-action title="Log out">
            <v-icon>perm_identity</v-icon>
          </v-list-tile-action>
        </v-list-tile>        
        

        <v-list-tile v-for="(r,i) in routes" :key="i" @click="changeRoute(r)" :title="r.title">
          <v-list-tile-action>
            <v-icon :color="title === r.title ? '#69BFBA' : ''">{{ r.icon }}</v-icon>
            
          </v-list-tile-action>
         
          <v-list-tile-content>
            <v-list-tile-title class="font-weight-thin">
              {{r.title}}
            </v-list-tile-title>
          </v-list-tile-content>
        
        
        </v-list-tile>

        
        
        
        
      </v-list>
    </v-navigation-drawer>

    <!-- <v-toolbar app fixed clipped-left v-if="currentRoute != 'SignIn'">
      <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
      <v-toolbar-title class="font-weight-thin">{{title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      
          <v-img
          :src="require('./assets/aal-brand-black.svg')"
          :contain="true" max-height="50"></v-img>
      
      
    </v-toolbar> -->

    
    <v-content class="logo">
      <transition name="fade">

        <router-view></router-view>
      </transition>

    </v-content>
  </v-app>
</template>

<script>
import { components } from 'aws-amplify-vue'
import Splash from './components/Splash'
import { Auth } from 'aws-amplify';

export default {
  name: 'App',
  components: {
    Splash,
    ...components
  },
  data () {
    return {
      drawer: null,

      routes: [
        //{ route: {name: 'welcome'}, icon: 'home', title: 'Welcome'},
        { route: 
          { name: 'modeldashboard' }, 
          icon: 'cloud_done', 
          title: 'Model Dashboard',
        },
        { route: {name: 'upload'}, icon: 'cloud_upload', title: 'Upload your data'},
        { route: {name: 'uploadedFiles'}, icon: 'folder', title: 'Previous files'},
        
      ]
    }
  },

  mounted(){

    this.$store.commit('baseUrl', this.$root.$data.baseurl);

    if(!this.currentModel){
      this.$router.push({ name: 'modeldashboard' })
    }

   
  },

  methods:{

    signOut(){
      Auth.signOut().then(()=>{
        this.$router.push({ name: 'signin'})
      })
    },

    changeRoute(r){
      this.$router.push(r.route)
    }
  },

  computed: {
      title() {
        return this.$store.getters["currentRoute"]
      },

      currentRoute(){
        return this.$store.getters["currentRoute"]
      },

      currentModel(){
        return this.$store.getters['currentModel']
      },

      showDrawer(){
        return this.currentRoute != 'SignIn';
      }
  }
}
</script>

<style>
.logo {
  height: 100vh; 
  /* background: url(/aal-logo-back.png) fixed bottom left no-repeat #43425D;  */
  background-size: 90vh; 
  display: flex; 
  justify-content: center; 
  background-color: rgb(245,245,245);
}
/* .logo img {max-width: 420px;} */

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .2s;
}

.fade-enter-active {
  transition-delay: .2s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}


</style>
