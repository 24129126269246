

export default {
    
    getProp(obj, props){

        var p = props.shift()
        if(props.length == 0){
            return obj[p];
        }
        
        return this.getProp(obj[p], props)              
    },


    setProp(obj, props, val){
        var p = props.shift()
        if(props.length === 0){
          obj[p] = val;
          return
        }
        return this.setProp(obj[p], props, val)
    },

    colorList: {

        purple: '#43425D',
        orange: '#FFC454',
        pink: '#FF548F',
        teal: '#69BFBA',
        lightteal: 'rgba(105,191,186,0.5)',
        grey: '#E5E5E5',
        darkgrey: 'rgba(100,100,100,0.5)',
        lightgrey: 'rgba(245,245,245)',
        green: '#54D968',
        white: '#FFFFFF',
        lightpurple: 'rgba(67,66,93,0.25)',
        skyblue: '#76D6FF'

    },

    getColor(col){
        return this.colorList[col]
    },

    round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    },

    
}