<template>
    
        <div>
            <div v-if="!ready" class="text-xs-center">

                <v-progress-circular indeterminate color="primary" v-if="!ready" class="mb-4"></v-progress-circular>
            </div>
            <div v-show="ready" style="min-height: 600px; max-width: 1900px; max-height: 3000px; border: none; margin: 5px;">
                <svg viewBox="-2500 -1200 5500 2200" ref="svgchart1" id="aalsvgchart1" style="min-height: 600px;" class="hidden">
                  
                </svg>
            </div>
           
        </div>
    
        
</template>


<script>

import * as d3 from "d3";
import { setTimeout } from 'timers';
import utils from '../utils';


export default {

    name: 'd3chart',

    props: ['item', 'ctq', 'toggletext', 'togglenonctqs'],

    data() {

        return {

            data: null,
            svg: null,
            zoomGroup: null,
            color: null,
            simulation: null,
            width: null,
            height: null,
            graph: null,
            ready: false,
            currentNode: null,
            colors: { 
                'out': '#FF548F',
                'in': '#54D968',
                'border': '#FFC454',
                'none': '#E5E5E5',
                'predictedout': '#FF548F',
                'predictedin': '#54D968',
                'predictedborder': '#FFC454',
            },
            selections: [],
            selectedCTQ: this.ctq,
            borders: [],
            nodes: [],
            toggleText: this.toggletext,
            toggleExplanatories: this.togglenonctqs,
            

            

        }
    },


    mounted(){
        
        this.initVariables();
        this.runD3();
        //window.addEventListener('resize', this.runD3)
        
    },

    watch:{

        'samenessItem': function(){
            this.ready = false;
            this.initVariables();
            this.runD3();
        },

        'ctq': function(n){
            this.selectedCTQ = n;
            
            this.updateBorder(n)
            
        },

        'toggletext': function(t){
            this.toggleText = t;
            
            this.updateText()
        },

        'togglenonctqs': function(t){
            this.toggleExplanatories = t;

            this.updateText()
        }
    },


    computed: {

        samenessItem(){
            return this.$store.getters['currentLot']
        },

        unitData(){
            return this.$store.getters['currentUnitData']
        },

        modelData(){
            var modelData = this.$store.getters["modelData"]
            var currentModel = this.$store.getters["currentModel"];
            return modelData[currentModel]
        },

        currentModel(){
            return this.$store.getters['currentModel']
        }, 

        trainingLimits(){
            return this.$store.getters['trainingLimits']
        },

       
        
    },



    methods:{

        highlightNode(n){
            this.updateBorder(n);
        },


        setReady(val){
            this.ready = val;
        },

        updateBorder(node){
            var self = this;
            self.node.selectAll(".bordercircle")
                .style("stroke", d => { 
                        if(node && node.id == d.id){
                            return utils.getColor('purple')
                        }
                        return self.getNodeBorderColor(d) 
                    })
                .style("stroke-width", d => {
                                        if((node && node.id == d.id) || d.id == this.selectedCTQ){
                                            return 14
                                        }
                                        else{
                                            return 4;
                                        } 
                })
                
        },

        updateText(){

            this.node.selectAll("text").remove()

            if(this.toggleText){

                var nd = this.node;

                var labels = this.node
                    .append("text")
                    .text(function(d) {
                        if(d.isCTQ){

                            return d.id;//.substring(0, 7) + '...';
                        }
                        
                    })
                    .attr('x', 6)
                    .attr('y', 3)
                    //.style('font-weight', 'bold')
                    .style('font-family', 'museo-sans')
                    .style('font-size', '1.5em')
                 
                    
            }

            if(this.toggleExplanatories){

                var nd = this.node;

                var labels = this.node
                    .append("text")
                    .text(function(d) {
                        if(!d.isCTQ){

                            return d.id;//.substring(0, 7) + '...';
                        }
                        
                    })
                    .attr('x', 6)
                    .attr('y', 3)
                    //.style('font-weight', 'bold')
                    .style('font-family', 'museo-sans')
                    .style('font-size', '1.5em')
                 
                    
            }
            
        },

        initVariables(){

            this.svg = d3.select("#aalsvgchart1");

            var defs = this.svg.append("defs");
            
            this.width = +this.svg.attr("width");
            this.height = +this.svg.attr("height");

            this.color = d3.scaleOrdinal(d3.schemeCategory20);

            const forceX = d3.forceX(this.width / 2).strength(function(d){
                // if(d.id === 'WPHM_VE_2_Gasflow6_med'){
                //     return 1
                // }
                return 0.04
            })
            const forceY = d3.forceY(this.height / 2).strength(function(d){
                // if(d.id === 'WPHM_VE_2_Gasflow6_med'){
                //     return 1
                // }
                return 0.06
            })

            this.simulation = d3.forceSimulation()
                .force("link", d3.forceLink().id(function(d) { return d.id; }))
                .force("charge", d3.forceManyBody().strength(-40))
                .force("center", d3.forceCenter(this.width / 2, this.height / 2))
                .force("collide", d3.forceCollide().radius(d => 92))
                .force('x', forceX)
                .force('y',  forceY)
                
                
            defs.selectAll("marker")
                .data(["end"])      // Different link/path types can be defined here
                .enter().append("svg:marker")    // This section adds in the arrows
                .attr("id", String)
                .attr("viewBox", "0 -5 10 10")
                .attr("refX", 60)
                .attr("refY", 0)
                .attr("markerWidth", 7.5)
                .attr("markerHeight", 7.5)
                .attr("orient", "auto")
                .append("svg:path")
                .attr("fill", function(d){
                    return utils.getColor('purple')
                })
                .attr("stroke", function(d){
                    return utils.getColor('purple')
                })
                .attr("d", "M0,-5L10,0L0,5");                
            
            
                
        },

        dragstarted(d) {
            if (!d3.event.active) this.simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        },

        dragged(d) {
            d.fx = d3.event.x;
            d.fy = d3.event.y;
        },

        dragended(d) {
            if (!d3.event.active) this.simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        },

        nodeSelected(d, i, j){
            //console.log('node selected:', d)
            var self = this;
            d.isCTQ = !d.isCTQ

            this.currentNode = d;
            this.selections.forEach(x => {

              d3.select(x).attr("fill", function(d){
                    return self.getNodeFillColor(d)
                })
            })
            
            this.selections.push(j[i]);

            d3.select(j[i]).attr("fill", function(d){
              return utils.getColor('skyblue')
            })

            this.$emit('nodeselected', d)
        },

        getNodeBorderColor(d){
            
            var prediction = this.samenessItem['Prob. ' + d.id];

            // if(this.currentNode && this.currentNode.id == d.id){
            //     return utils.getColor('purple')
            // }
            
            if(this.selectedCTQ && this.selectedCTQ === d.id){
                return utils.getColor('purple')
            }

            if(prediction === ''){
                return utils.getColor('lightpurple')
            }
            
            if(prediction === 1){
                return utils.getColor('green')
            }

            if(prediction === 0){

                var limit = this.trainingLimits.find(x => {
                    return x.Name === d.id;
                })

                if(Number(this.unitData[d.id]) === Number(limit.Lower)){
                    return utils.getColor('green')
                }

                return utils.getColor('pink')
            }

            if(prediction >= 0.95){
                return utils.getColor('green')
            }

            if(prediction >= 0.80 && prediction < 0.95){
                return utils.getColor('orange')
            }

            if(prediction < 0.80){
                return utils.getColor('pink')
            }

            

            return utils.getColor('darkgrey')
            
        },

        getNodeFillColor(d){

            // if(d.id.indexOf('RES_SHIP')> -1){
            //     //console.log('res_ship', d)
            //     console.log('samenessitem:', this.samenessItem[d.id])
            //     console.log('prob. ', this.samenessItem['Prob. ' + d.id])
                
            // }
            
            if(this.currentNode && this.currentNode.id == d.id){
                return utils.getColor('purple')
            }
            
            if(d.isCTQ){
                var prediction = this.samenessItem['Prob. ' + d.id];
                if(prediction === ''){
                    //return this.colorList['grey']
                    return utils.getColor('white')
                }

                if(prediction === 1){
                    return utils.getColor('green')
                }

                if(prediction === 0){
                    return utils.getColor('pink')
                }

                return utils.getColor('white')
            }
            else{

                var props = d.id.split('.')
                //console.log(props)
                var meas = utils.getProp(this.unitData, props)
                //console.log(d.id, meas)
                if(meas != null && meas != undefined && meas.toLowerCase() != 'na'){
                    return utils.getColor('darkgrey')
                }
                return utils.getColor('white')
            }
        },

        runD3(){

            var self = this;

            self.svg.selectAll("g").remove();

            
            

            this.$store.dispatch("getModelData", { id: this.currentModel }).then(function(graph){

                self.data = graph;

                var tooltip = d3.select("body")
                .append("div")
                .attr("class", "tooltip")
                .style("opacity", 0);

                self.zoomGroup = self.svg.append("g")
                    
                    .attr("class", "zoomgroup")
                     

                var link = self.zoomGroup.append("g")
                    .attr("class", "links")
                    .selectAll("line")
                    .data(graph.links)
                    .enter().append("line")
                    .attr("stroke-width", function(d) { 
                        //return Math.sqrt(d.strength); 
                        return 2;//d.strength
                    })
                     .attr("marker-end", "url(#end)");


                var node = self.zoomGroup.append("g")
                    .attr("id", "nodegroup")
                    .attr("class", "nodes")
                    .selectAll("g")
                    .data(graph.nodes)
                    .enter().append("g")

                self.node = node;
    
                self.borders = node.append("circle")
                    .attr("class", "bordercircle")
                    .attr("r", 30)
                    .style("stroke", function(d){
                        //return self.colors[d.falls];
                        return self.getNodeBorderColor(d)
                    })
                    .style("stroke-width", function(d){
                        return 4
                    })
                    .attr("fill", function(d) { 
                        return /*color(d.group)*/ '#FFF'; 
                    })
                    .call(d3.drag()
                        .on("start", self.dragstarted)
                        .on("drag", self.dragged)
                        .on("end", self.dragended));
                    
                var innerCircles = node.append("circle")
                    .attr("r", 25)
                    .attr("fill", function(d){
                       return self.getNodeFillColor(d)
                    })
                    .call(d3.drag()
                    .on("start", self.dragstarted)
                    .on("drag", self.dragged)
                    .on("end", self.dragended))
                    .on("click", self.nodeSelected)
                    .on("mouseover", d => {
                            
                            tooltip.transition()
                            .duration(300)
                            .style("opacity", .8);
                            tooltip.html(
                                "<p>" + d.id + "</p>"
                            )
                            .style("left", (d3.event.pageX) + "px")
                            .style("top", (d3.event.pageY + 10) + "px");
                    })
                    .on("mouseout", function(d) {
                        tooltip.transition()
                            .duration(500)
                            .style("opacity", 0);
                        });

                if(self.toggleText){
                    var labels = node.append("text")
                    .text(function(d) {
                        if(d.isCTQ){

                            return d.id;//.substring(0, 7) + '...';
                        }
                        return '';
                    })
                    .attr('x', 6)
                    .attr('y', 3)
                    //.style('font-weight', 'bold')
                    .style('font-family', 'museo-sans')
                    .style('font-size', '1.5em')
                }
                


                //node.append("title")
                //    .text(function(d) { return d.id; });



                self.simulation
                    .nodes(graph.nodes)
                    .on("tick", ticked)
                    .on('end', function() {
                        //self.svg.classed('hidden', false)
                        // self.ready = true;
                        
                    });

                self.simulation.force("link")
                    .links(graph.links)
                    .distance(150)
                    .strength(2)
                    
                

                function zoom_actions(){
                     
                     self.zoomGroup.attr("transform", d3.event.transform)
                }
                
                var zoom_handler = d3.zoom().on("zoom", zoom_actions);
                zoom_handler(self.svg); 

                

                function ticked() {
                    link
                        .attr("x1", function(d) { return ( d.source.x); })
                        .attr("y1", function(d) { return ( d.source.y); })
                        .attr("x2", function(d) { return ( d.target.x); })
                        .attr("y2", function(d) { return ( d.target.y); });

                    node
                        .attr("transform", function(d) {
                        return "translate(" + d.x + "," + d.y + ")";
                        })
                }
                
                setTimeout(() => {
                    self.ready = true;
                    
                    // self.svg.transition()
                    //         .duration(9000)
                    //         .style("opacity", 1);
                    //self.zoomGroup.attr("transform","translate(-100,-200)scale(.65,.65)"); 
                }, 2000)

            })
        }
    }
    
}
</script>


<style>

    .links line {
        stroke: #999;
        stroke-opacity: 0.6;
    }
    
    .nodes circle {
        stroke: #fff;
        stroke-width: 1.5px;
    }
    
    text {
        /* font-family: sans-serif; */
        font-size: 15px;
    }

    div.tooltip {
        position: absolute;
        text-align: center;
        
        height: 28px;
        padding: 4px;
        font-size: 0.7em; 
        background: rgba(245,245,245);
        border: 0px;
        
        pointer-events: none;
    }

    .hidden {
        /* visibility: hidden */
        /* opacity: 0.1; */
    }
</style> 