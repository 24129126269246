<template>

<v-container class="grey lighten-4" fluid>

    <v-layout row class="mb-4 mx-4" wrap>
        <v-flex xs12 sm8>
            <h2 class=" unit-title">Unit Detail <span>- {{ selectedItem.lot }}</span></h2>
        </v-flex>
        <v-flex xs12 sm4 class="text-xs-right">
            <v-btn color="primary" @click="goBack">Back</v-btn>
        </v-flex>

    </v-layout>
    
    <v-card class="mb-5 px-3 grey lighten-4 elevation-0" color="">
            
            <detail-view @goback="goBack"></detail-view>
            
            <div>
                <v-btn color="primary" @click="goBack">Back</v-btn>
            </div>
        
    </v-card>
   
    
</v-container>



</template>

<script>

import DetailView from './Detail.vue'

export default {

    name: 'UnitDetails',

    components:{
        DetailView
    },

    mounted(){
       
    },

    methods:{

        goBack(){
            this.$router.push({ name: 'units' } )
        }
    },

    computed:{
        selectedItem(){
            
            return this.$store.getters['currentLot']
        }
    }
    
}
</script>
