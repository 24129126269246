
<template>

<v-data-table :headers="headers" :items="fileList" class="elevation-1" :rows-per-page-items='[10,25,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'>

    <template v-slot:items="props">
      <td><v-icon class="secondary--text" :disabled="true">cloud_download</v-icon></td>
      <td>{{ props.item.id }}</td>
      <td class="">{{ props.item.name }}</td>
      <td class="">{{ props.item.dateUploaded | formatDate }}</td>
      <td class="">{{ props.item.modelid }}</td>
      <td class="">{{ props.item.processed }}</td>
      <td class="">{{ props.item.user }}</td>
      <!-- <td><a><v-icon class="error--text">keyboard_arrow_right</v-icon></a></td> -->
    </template>
  
</v-data-table>


</template>

<script>

import moment from 'moment';


export default {
    
    name: 'FileList',

    props: ['filelist'],

    watch: {

        // 'filelist': function(oldval, newval){
        //     this.fileList = newval;
        //     console.log(this.fileList)
        // }
    },

    filters:{

        formatDate(val){
            return moment(val).format('DD/MM/YYYY HH:mm')
        }
    },

    data(){
        
        return {

            headers: [
                { text: 'Download', sortable: false,  value: '' },
                { text: 'Id', value: 'id'},
                { text: 'File name', value: 'name' },
                { text: 'Uploaded on', value: 'dateUploaded' },
                { text: 'Model', value: 'modelid' },
                { text: 'Status', value: 'processed' },
                { text: 'User', value: 'user'},
                // { text: 'View results', sortable: false, value: '' }
            ],

            loading: false
            //fileList: this.filelist

        }

    },

    computed: {
        fileList(){
            return this.$store.getters['fileList']
        }
    }

    
}
</script>

