<template>
  <v-container>
    <v-layout
      text-xs-center
      wrap
    >
      <!-- <v-flex xs12>
        <v-img
          :src="require('../assets/aal.svg')"
          class="my-3 mb-5"
          contain
          height="100"
        ></v-img>
      </v-flex> -->

      <v-flex mb-4>
        <h1 class="display-2 font-weight-bold mb-3">
          <!-- Advanced Analytics Labs -->
        </h1>
        <p class="subheading font-weight-regular">
            <!-- analytics dashboard -->
        </p>
      </v-flex>

      <v-flex
        mb-5
        xs12
      >
        <h2 class="headline font-weight-bold mb-3">Welcome</h2>

        <v-layout justify-center>
          <a
            v-for="(next, i) in whatsNext"
            :key="i"
            :href="next.href"
            class="subheading mx-3"
          >
            {{ next.text }}
          </a>
        </v-layout>
      </v-flex>

      <!-- <v-flex
        xs12
        mb-5
      >
        <h2 class="headline font-weight-bold mb-3">Important Links</h2>

        <v-layout justify-center>
          <a
            v-for="(link, i) in importantLinks"
            :key="i"
            :href="link.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ link.text }}
          </a>
        </v-layout>
      </v-flex> -->

      <!-- <v-flex
        xs12
        mb-5
      >
        <h2 class="headline font-weight-bold mb-3">Ecosystem</h2>

        <v-layout justify-center>
          <a
            v-for="(eco, i) in ecosystem"
            :key="i"
            :href="eco.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ eco.text }}
          </a>
        </v-layout>
      </v-flex> -->

      
    </v-layout>
  </v-container>
</template>

<script>

  export default {
    data: () => ({
      
      whatsNext: [
        {
          text: 'Submit a dataset to your model',
          href: '#/dashboard'
        },
        

      ]
    })
  }
</script>

<style>

</style>
