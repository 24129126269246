<template>

        <v-container class="px-5 grey lighten-4" fluid>
        <h2 class="mb-4 unit-title">Sign In</h2>

        <v-card>
            
           
                
            <div class="mx-4 py-4">

                <amplify-authenticator :authConfig="authConfig"></amplify-authenticator>

                 
            </div>
                
            
        </v-card>

        
        
    </v-container>

    
</template>

<script>

import { AmplifyEventBus } from "aws-amplify-vue"

export default {

    data(){

        return {
            theme: {

            },

            authConfig: {
                signUpConfig: { hideAllDefaults: true}
            }
        }
    },

    mounted(){
        this.$store.commit('currentRoute', 'SignIn')

        AmplifyEventBus.$on("authState", eventInfo => {
            

            this.$router.push({name:'modeldashboard'})
        })
    },
}
</script>

<style>

span.Section__sectionFooterSecondaryContent___3cjOa{
    display: none !important;
}

.Hint__hint___3Hrww{
    display: none !important;
}

div.error{
    margin-top: -40px;
    margin-left: -24px;
    background-color: #FFF !important;
    color: #FF548F;
    font-size: 0.8em;
}

[data-test="authenticator-error"]{
    background-color: #FFF !important;
    color: #FF548F;
    
}





</style>