<template>
<v-card>

    <v-container fluid>
        <div>

            <detail-view :item="selectedItem"></detail-view>
        </div>

    </v-container>
</v-card>

</template>

<script>

import DetailView from './Detail.vue'

export default {
    name: 'TestDetail',

    components: {
        DetailView
    },

    data(){
        return {
            selectedItem: { lot: '3JKL67', sameness_score: 82.123 }
        }
    }
}
</script>
